import toArray from 'lodash/toArray';
import each from 'lodash/each';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import $ from 'jquery';

let counter = 1;

const watchers = {
	selectors: {},
	nodes: {},
};

/**
 * Checks if node represents an element node (nodeType === 1).
 *
 * @param {HTMLElement} node
 * @returns {Boolean}
 */
const isElementNode = node => node.nodeType === 1;

/**
 * Extracts all child descendant
 * elements of a specified node.
 *
 * @param {HTMLElement} node
 * @returns {Array}
 */
const extractChildren = node => toArray(node.querySelectorAll('*'));

/**
 * Extracts node identifier. If ID is not specified,
 * then it will be created for the provided node.
 *
 * @param {HTMLElement} node
 * @returns {Number}
 */
const getNodeId = node => {
	let id = node._observeId;

	if (!id) id = node._observeId = counter++;

	return id;
};

/**
 * Invokes callback passing node to it.
 *
 * @param {HTMLElement} node
 * @param {Object} data
 */
const trigger = (node, data) => {
	const id = getNodeId(node);
	const ids = data.invoked;

	if (ids.includes(id)) {
		return;
	}

	data.callback(node);
	data.invoked.push(id);
};

/**
 * Adds node to the observer list.
 *
 * @param {HTMLElement} node
 * @returns {Object}
 */
const createNodeData = node => {
	const nodes = watchers.nodes;
	const id = getNodeId(node);

	nodes[id] = nodes[id] || {};

	return nodes[id];
};

/**
 * Returns data associated with a specified node.
 *
 * @param {HTMLElement} node
 * @returns {Object|Undefined}
 */
const getNodeData = node => watchers.nodes[node._observeId];

/**
 * Removes data associated with a specified node.
 *
 * @param {HTMLElement} node
 */
const removeNodeData = node => delete watchers.nodes[node._observeId];

/**
 * Adds removal listener for a specified node.
 *
 * @param {HTMLElement} node
 * @param {Object} data
 */
const addRemovalListener = (node, data) => {
	const nodeData = createNodeData(node);

	(nodeData.remove = nodeData.remove || []).push(data);
};

/**
 * Adds listener for the nodes which matches specified selector.
 *
 * @param {String} selector - CSS selector.
 * @param {Object} data
 */
const addSelectorListener = (selector, data) => {
	const storage = watchers.selectors;

	(storage[selector] = storage[selector] || []).push(data);
};

/**
 * Calls handlers assocoiated with an added node.
 * Adds listeners for the node removal.
 *
 * @param {HTMLElement} node - Added node.
 */
const processAdded = node => {
	each(watchers.selectors, (listeners, selector) => {
		listeners.forEach(data => {
			if (!data.ctx.contains(node) || !$(node, data.ctx).is(selector)) {
				return;
			}

			if (data.type === 'add') {
				trigger(node, data);
			} else if (data.type === 'remove') {
				addRemovalListener(node, data);
			}
		});
	});
};

/**
 * Calls handlers assocoiated with a removed node.
 *
 * @param {HTMLElement} node - Removed node.
 */
const processRemoved = node => {
	const nodeData = getNodeData(node);
	const listeners = nodeData && nodeData.remove;

	if (!listeners) return;

	listeners.forEach(data => trigger(node, data));

	removeNodeData(node);
};

/**
 * Removes all non-element nodes from provided array
 * and appends to it descendant elements.
 *
 * @param {Array} nodes
 * @returns {Array}
 */
const formNodesList = nodes => {
	const result = [];
	const filteredNodes = toArray(nodes).filter(isElementNode);

	for (var current, i = 0, len = filteredNodes.length; i < len; i++) {
		current = filteredNodes[i];
		result.push(current);

		Array.prototype.push.apply(result, extractChildren(current));
	}

	return result;
};

/**
 * Collects all removed and added nodes from
 * mutation records into separate arrays
 * while removing duplicates between both types of changes.
 *
 * @param {Array} mutations - An array of mutation records.
 * @returns {Object} Object with 'removed' and 'added' nodes arrays.
 */
const formChangesLists = mutations => {
	let removed = [];
	let added = [];

	for (var current, i = 0, len = mutations.length; i < len; i++) {
		current = mutations[i];
		Array.prototype.push.apply(removed, toArray(current.removedNodes));
		Array.prototype.push.apply(added, toArray(current.addedNodes));
	}

	removed = removed.filter(node => {
		const addIndex = added.indexOf(node);

		if (addIndex !== -1) {
			added.splice(addIndex, 1);
		}

		return addIndex === -1;
	});

	return {
		removed: formNodesList(removed),
		added: formNodesList(added),
	};
};

const globalObserver = new MutationObserver(mutations => {
	const changes = formChangesLists(mutations);

	changes.removed.forEach(processRemoved);
	changes.added.forEach(processAdded);
});

globalObserver.observe(document.body, {
	subtree: true,
	childList: true,
});

export default {
	/**
	 * Adds listener for the appearance of nodes that matches provided
	 * selector and which are inside of the provided context. Callback will be
	 * also invoked on elements which a currently present.
	 *
	 * @param {String} selector - CSS selector.
	 * @param {Function} callback - Function that will invoked when node appears.
	 * @param {HTMLElement} [ctx=document.body] - Context inside of which to search for the node.
	 */
	get: (selector, callback, ctx) => {
		const data = {
			ctx: ctx || document.body,
			type: 'add',
			callback: callback,
			invoked: [],
		};

		const nodes = $(selector, data.ctx).toArray();

		nodes.forEach(node => trigger(node, data));

		addSelectorListener(selector, data);
	},

	/**
	 * Adds listener for the nodes removal.
	 *
	 * @param {(jQueryObject|HTMLElement|Array|String)} selector
	 * @param {Function} callback - Function that will invoked when node is removed.
	 * @param {HTMLElement} [ctx=document.body] - Context inside of which to search for the node.
	 */
	remove: (selector, callback, ctx) => {
		let nodes = [];
		const data = {
			ctx: ctx || document.body,
			type: 'remove',
			callback: callback,
			invoked: [],
		};

		if (typeof selector === 'object') {
			nodes = !isUndefined(selector.length) ? toArray(selector) : [selector];
		} else if (isString(selector)) {
			nodes = $(selector, ctx).toArray();

			addSelectorListener(selector, data);
		}

		nodes.forEach(node => addRemovalListener(node, data));
	},

	/**
	 * Removes listeners.
	 *
	 * @param {String} selector
	 * @param {Function} [fn]
	 */
	off: (selector, fn) => {
		const selectors = watchers.selectors;
		const listeners = selectors[selector];

		if (selector && !fn) {
			delete selectors[selector];
		} else if (listeners && fn) {
			selectors[selector] = listeners.filter(data => data.callback !== fn);
		}
	},
};
