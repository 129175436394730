const {isGTMEnabled} = require('../components/helpers');

module.exports = {
	addToCart({
		productSelector = '.add-to-cart',
		productDetails = '.product-info-content',
		productPriceSelector = '[data-gtm-price]',
		productTileNameSelector = '.line-item-name',
		productTileDescriptionSelector = '.line-item-subtitle',
		productQuantitySelector = '.custom-select',
		productCurrencySelector = 'gtm-currency',
		productAttributes = 'gtm-attributes',
	} = {}) {
		if (!isGTMEnabled()) {
			return;
		}
		const isWishlistPage = $('body').find('.wishlistItemCards').length || null;
		if (isWishlistPage) {
			$(document).ready(function() {
				jQuery('body').on('click',".wishlistItemCards .add-to-cart", function (e) {
						e.preventDefault();
						const gtmData = $(this).data('gtm');
						const $product = $(this).closest(productSelector);
						const $productDetails = $(this).closest(productDetails);
						const sku = $product.data('pid');
						const nameOne = $productDetails
							.find(productTileNameSelector)
							.text()
							.trim();
						const nameTwo = $productDetails
							.find(productTileDescriptionSelector)
							.text()
							.trim();
						const name = `${nameOne} - ${nameTwo}`;
						const price = $productDetails
							.find(productPriceSelector)
							.find('.value')
							.attr('content');
						const quantity = $productDetails
							.find(productQuantitySelector)
							.find('option:selected')
							.text()
							.trim();
						const currency = $product.data(productCurrencySelector)? $product.data(productCurrencySelector): 'AUD';
						var attributes = $productDetails.data(productAttributes);

						const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
						const variant = $productDetails.find(".item-attributes .line-item-attributes").text().trim().split(":")[1];
						const index = $($(".card.product-info")).index($(this).closest('.product-info'));
						const category = $product.data('product-category');
						const priceObj = $product.data('product-priceobj');
						const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
						const promotions = $product.data('promotions');
						var promotionList = [];
						if (promotions) {
							for (let i = 0; i < promotions.length; i++) {
								promotionList.push({
									id: promotions[i].id,
									name: promotions[i].name
								})
							}
						}
						const categoryData = {
							category1: category && category.length > 0 && category[0] ? category[0].name : "",
							category2: category && category.length > 0 && category[1] ? category[1].name : "",
							category3: category && category.length > 0 && category[2] ? category[2].name : "",
							category4: category && category.length > 0 && category[3] ? category[3].name : "",
							category5: category && category.length > 0 && category[4] ? category[4].name : "",
							list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
							list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
						};

					if (!attributes) {
						const instock = $product.data('product-instock');
						attributes = instock ? 'In Stock' : 'Out Of Stock';
					}

						$('body').bootstrap('gtm', {
							type: 'addToCart',
							data: gtmData,
						});
						$('body').bootstrap('gtm', {
							type: 'add_to_cart',
							data: gtmData,
						});
						// Add pinterest tag
						var pScript = document.createElement("script");
						pScript.innerHTML = "pintrk('track', 'AddToCart', {value: " + price + ",order_quantity: " + quantity + ",currency: 'AUD'});";
						document.head.appendChild(pScript);
						$("<noscript><img height='1' width='1' style='display:none;' alt='' src='https://ct.pinterest.com/v3/?tid=2613557919181&event=AddToCart&ed[value]="+ price +"&ed[order_quantity]="+ quantity +"&noscript=1' /></noscript>").appendTo(document.head);
					});
			});
		}
	},
};
