const { isGTMEnabled } = require('../components/helpers');

const base = require('sfraScripts/product/base');
var trustpilotWidgetStatus = 0;
var trustpilotWidgetonEienstienStatus = 0;
/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
	var miniQuantity = $('.minicart .minicart-quantity');
    if (!miniQuantity.hasClass('show')) {
        miniQuantity.addClass('show');
    }
	miniQuantity.text(response.quantityTotal);
	const messageType = response.error ? 'alert-danger' : 'alert-success';

	if ($('.add-to-cart-from-tile-messages').length === 0) {
		$('body').append('<div class="add-to-cart-from-tile-messages"></div>');
	}

	$('.add-to-cart-from-tile-messages').append(
		`<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${response.message}</div>`,
	);

	setTimeout(function () {
		$('.add-to-basket-alert').remove();
	}, 5000);
}

function updateTile(selector) {
	const $tile = selector
		.parents()
		.eq(5)
		.find('.product-tile-card');
	const imgData = $(selector).data('image');
	const imgDataNext = $(selector).data('imagenext');
	const swatchPdpUrl = $(selector).data('pdpurl');
	const imgDataURL = imgData && imgData.url ? imgData.url : '';
	const imgDataNextURL = imgDataNext && imgDataNext.url ? imgDataNext.url : imgDataURL;

	$tile.find('.plp-image-link').attr('href', swatchPdpUrl);
	$tile.find('.card-img-lifestyle-main').attr('src', imgDataURL);
	$tile.find('.card-img-lifestyle-main').attr('data-src', imgDataURL);
	$tile.find('.card-img-lifestyle-second').attr('src', imgDataNextURL);
	$tile.find('.card-img-lifestyle-second').attr('data-src', imgDataNextURL);
}

function selectCategoryMattress() {
	if($('.mattress-sizes').length > 0){
		var categoryId = $('.category-id').val();
		if (categoryId) {
			$('.mattress-sizes li').each(function() {
				var cardBody = $(this).closest('.col-6.col-lg-4').find('.card-body');
				var typeSize = $(this).find('.size-link').data('variation-id');
				var pdpUrl = $(this).find('.size-link').data('pdpurl');
				if ($(this).data('category-id') === categoryId) {
					updateTileMattress($(this).find('.mattress-sizes-item'));
					updateAttributeMattress($(this).find('.mattress-sizes-item'));
					cardBody.find('.mattress-sizes li').removeClass('selected');
					cardBody.find('.mattress-sizes li[data-category-id="'+categoryId+'"]').addClass('selected');
					cardBody.find('.mattress-feel-label').addClass('d-none');
					cardBody.find('.mattress-feel-label[data-size-id="'+typeSize+'"]').removeClass('d-none');
					cardBody.find('.mattress-feel-label[data-size-id="'+typeSize+'"]').attr('href', pdpUrl);
					const price = $(this).find('.size-link').data('price');
					$(this).closest('.card-body').find('.product-tile-price .sales .value').attr('content', price.sales.formatted);
					$(this).closest('.card-body').find('.product-tile-price .sales .value').text(price.sales.formatted);
					if (!price.list) {
						$(this).closest('.card-body').find('.product-tile-price del').addClass('d-none');
					} else {
						$(this).closest('.card-body').find('.product-tile-price del').removeClass('d-none');
						$(this).closest('.card-body').find('.product-tile-price .list .value').attr('content', price.list.formatted);
						$(this).closest('.card-body').find('.product-tile-price .list .value').html('<span class="sr-only"></span>' + price.list.formatted + '<span class="sr-only"></span>');
					}
				}
			});
		}
	}
}

function updateTileMattress(selector, option) {
	const $tile = selector
		.parents()
		.eq(5)
		.find('.product-tile-card');
	var imgData = $(selector).data('image');
	var imgDataNext = $(selector).data('imagenext');

	if (option === 'mouseleave') {
		imgData = $(selector).find('.size-link').data('image');
		imgDataNext = $(selector).find('.size-link').data('imagenext');
	}
	const swatchPdpUrl = $(selector).data('pdpurl');
	const imgDataURL = imgData && imgData.url ? imgData.url : '';
	const imgDataNextURL = imgDataNext && imgDataNext.url ? imgDataNext.url : imgDataURL;

	$tile.find('.plp-image-link').attr('href', swatchPdpUrl);
	$tile.find('.plp-image-link link[itemprop=image]').attr('href', imgDataURL);
	$tile.find('.card-img-lifestyle-main').attr('src', imgDataURL);
	$tile.find('.card-img-lifestyle-main').attr('data-src', imgDataURL);
	$tile.find('.card-img-lifestyle-main').attr('data-srcset',imgDataURL);
	$tile.find('.card-img-lifestyle-main').attr('srcset',imgDataURL);
	$tile.find('.card-img-lifestyle-second').attr('src', imgDataNextURL);
	$tile.find('.card-img-lifestyle-second').attr('data-src', imgDataNextURL);
	$tile.find('.card-img-lifestyle-second').attr('data-srcset', imgDataNextURL);
	$tile.find('.card-img-lifestyle-second').attr('srcset', imgDataNextURL);
}

function updateAttributeMattress($this) {
	const $tile = $this.parents().eq(5);
	const productCard = $tile.find('.product-tile-card');
	const qwButtons = $tile.find('.qw-buttons');
	productCard.find('.product-tile-name').text($this.data('productname'));
	productCard.find('.product-tile-range').text($this.data('brand'));
	productCard.find('.product-tile-subtitle .short-description').text($this.data('shortdescription'));

	var onlineonly = $this.data('onlineonly') ? $this.data('onlineonly') : $this.find('.mattress-sizes-item').data('onlineonly');

	if (!onlineonly) {
		productCard.find('.product-tile-mto-mattress').removeClass('d-none');
		productCard.find('.product-tile-exclusive-mattress').addClass('d-none');
	} else {
		productCard.find('.product-tile-mto-mattress').addClass('d-none');
		productCard.find('.product-tile-exclusive-mattress').removeClass('d-none');
	}

	if (!qwButtons.data('store')) {
		if ($this.data('onlineonly')) {
			qwButtons.find('.is-mattress-add-to-cart').addClass('details-button');
			qwButtons.find('.is-mattress-add-to-cart').removeClass('quick-view-button');
		} else {
			qwButtons.find('.is-mattress-add-to-cart').addClass('quick-view-button');
			qwButtons.find('.is-mattress-add-to-cart').removeClass('details-button');
		}
	}

	var isCommingSoon = $this.data('is-commingsoon') ? $this.data('is-commingsoon') : $this.find('.mattress-sizes-item').data('is-commingsoon');
	if (!isCommingSoon) {
		qwButtons.find('.is-mattress-add-to-cart').removeClass('d-none');
	} else {
		qwButtons.find('.is-mattress-add-to-cart').addClass('d-none');
	}
}

module.exports = {
	productClick({
		productSelector = '.product',
		productPriceSelector = '[data-gtm-price]',
		productTileNameSelector = '.product-tile-name',
		productTileDescriptionSelector = '.product-tile-subtitle',
		productLinkSelector = '[data-gtm-product-link]',
	} = {}) {
		if (!isGTMEnabled()) {
			return;
		}

		$('body').on('click.ewave.gtm', productLinkSelector, function (e) {
			const $product = $(this).closest(productSelector);
			const gtmData = $product.data('gtm');

			if (!gtmData) return true;

			e.preventDefault();
			const sku = `${$product.data('pid')}`;
			const nameOne = $product
				.find(productTileNameSelector)
				.text()
				.trim();
			const nameTwo = $product
				.find(productTileDescriptionSelector)
				.text()
				.trim();
			const name = `${nameOne} - ${nameTwo}`;
			const price = $product
				.find(productPriceSelector)
				.find('.value')
				.attr('content');
			const url = e.currentTarget.href;

			$('body').bootstrap('gtm', {
				type: 'productClick',
				data: gtmData,
			});

			const $productData = $product.find(".card-body");

			const brand = $productData.data('product-brand') ? $productData.data('product-brand') : "";
			const variant = $productData.data('product-variant') ? $productData.data('product-variant') : "";
			const currency = $productData.data('product-currency') ? $productData.data('product-currency') : 'AUD';
			const category = $productData.data('product-category');
			const index = $($(".product")).index($product);
			const priceObj = $productData.data('product-priceobj');
			const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
			const promotions = $productData.data('promotions');
			var promotionList = [];
			if (promotions) {
				for (let i = 0; i < promotions.length; i++) {
					promotionList.push({
						id: promotions[i].id,
						name: promotions[i].name
					})
				}
			}
			const categoryData = {
				category1: category && category.length > 0 && category[0] ? category[0].name : "",
				category2: category && category.length > 0 && category[1] ? category[1].name : "",
				category3: category && category.length > 0 && category[2] ? category[2].name : "",
				category4: category && category.length > 0 && category[3] ? category[3].name : "",
				category5: category && category.length > 0 && category[4] ? category[4].name : "",
				list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
				list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
			}
		});

		$('body').on('click', '.card.product-info .line-item-name', function (e) {
			var productSelector = '.add-to-cart',
				productDetails = '.product-info-content',
				productPriceSelector = '[data-gtm-price]',
				productTileNameSelector = '.line-item-name',
				productTileDescriptionSelector = '.line-item-subtitle',
				productQuantitySelector = '.custom-select',
				productCurrencySelector = 'gtm-currency',
				productAttributes = 'gtm-attributes';
			var addtocartbutton = $(this).closest('.product-info-content').find('.add-to-cart');
			if (addtocartbutton.length > 0) {
				const $product = addtocartbutton.closest(productSelector);
				const $productDetails = addtocartbutton.closest(productDetails);
				const sku = $product.data('pid');
				const nameOne = $productDetails
					.find(productTileNameSelector)
					.text()
					.trim();
				const nameTwo = $productDetails
					.find(productTileDescriptionSelector)
					.text()
					.trim();
				const name = `${nameOne} - ${nameTwo}`;
				const price = $productDetails
					.find(productPriceSelector)
					.find('.value')
					.attr('content');
				const quantity = $productDetails
					.find(productQuantitySelector)
					.find('option:selected')
					.text()
					.trim();
				const currency = $product.data(productCurrencySelector) ? $product.data(productCurrencySelector) : 'AUD';
				const attributes = $productDetails.data(productAttributes);

				const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
				const variant = $productDetails.find(".item-attributes .line-item-attributes").text().trim().split(":")[1];
				const index = $($(".card.product-info")).index($(this).closest('.product-info'));
				const category = $product.data('product-category');
				const priceObj = $product.data('product-priceobj');
				const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
				const promotions = $product.data('promotions');
				var promotionList = [];
				if (promotions) {
					for (let i = 0; i < promotions.length; i++) {
						promotionList.push({
							id: promotions[i].id,
							name: promotions[i].name
						})
					}
				}
				const categoryData = {
					category1: category && category.length > 0 && category[0] ? category[0].name : "",
					category2: category && category.length > 0 && category[1] ? category[1].name : "",
					category3: category && category.length > 0 && category[2] ? category[2].name : "",
					category4: category && category.length > 0 && category[3] ? category[3].name : "",
					category5: category && category.length > 0 && category[4] ? category[4].name : "",
					list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : '',
					list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : '',
				}

				const gtmData = $product.data('gtm');
				$('body').bootstrap('gtm', {
					type: 'select_item',
					data: gtmData,
				});
			} else if ($(this).closest('.product-info-content').hasClass('custom-css')) {
				var $product = $(this).closest('.custom-css');
				const sku = $product.find('.remove-product').data('pid');
				const name = $(this).text().trim() + ' - ' + $(this).next().text().trim();
				const quantity = parseInt($product.find('select.amart-quantity').data('pre-select-qty'));
				const price = $product.data('product-price') ? $product.data('product-price') : "";
				const currency = $product.data('product-currency') ? $product.data('product-currency') : "AUD";
				const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
				const variant = $product.data('product-variant') ? $product.data('product-variant') : "";
				const index = $($(".card.product-info")).index($(this).closest('.product-info'));
				const category = $product.data('product-category');
				const discount = parseFloat(price * quantity - $product.find('.pricing.line-item-total-price-amount').text().trim().replace(/[$,]/g, '')).toFixed(2);
				const promotions = $product.data('promotions');
				var promotionList = [];
				if (promotions) {
					for (let i = 0; i < promotions.length; i++) {
						promotionList.push(promotions[i].name);
					}
				}
				const categoryData = {
					category1: category && category.length > 0 && category[0] ? category[0].name : "",
					category2: category && category.length > 0 && category[1] ? category[1].name : "",
					category3: category && category.length > 0 && category[2] ? category[2].name : "",
					category4: category && category.length > 0 && category[3] ? category[3].name : "",
					category5: category && category.length > 0 && category[4] ? category[4].name : "",
					list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : '',
					list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : '',
				}

				const gtmData = $product.data('gtm');
				$('body').bootstrap('gtm', {
					type: 'select_item',
					data: gtmData,
				});
			} else {
				const $product = $(this).closest(productDetails).find('.remove-from-wishlist').parent();
				const $productDetails = $(this).closest(productDetails);
				const sku = $product.data('pid');
				const nameOne = $productDetails
					.find(productTileNameSelector)
					.text()
					.trim();
				const nameTwo = $productDetails
					.find(productTileDescriptionSelector)
					.text()
					.trim();
				const name = `${nameOne} - ${nameTwo}`;
				const price = $productDetails
					.find(productPriceSelector)
					.find('.value')
					.attr('content');
				const quantity = $productDetails
					.find(productQuantitySelector)
					.find('option:selected')
					.text()
					.trim();

				const currency = $product.data(productCurrencySelector) ? $product.data(productCurrencySelector) : 'AUD';
				const attributes = $product.data(productAttributes);

				const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
				var variant = $productDetails.find(".item-attributes .line-item-attributes").text().trim().split(":")[1];
				if (!variant) {
					variant = "";
				}
				const index = $($(".card.product-info")).index($(this).closest('.product-info'));
				const category = $product.data('product-category');
				const priceObj = $product.data('product-priceobj');
				const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
				const promotions = $product.data('promotions');
				var promotionList = [];
				if (promotions) {
					for (let i = 0; i < promotions.length; i++) {
						promotionList.push({
							id: promotions[i].id,
							name: promotions[i].name
						})
					}
				}
				const categoryData = {
					category1: category && category.length > 0 && category[0] ? category[0].name : "",
					category2: category && category.length > 0 && category[1] ? category[1].name : "",
					category3: category && category.length > 0 && category[2] ? category[2].name : "",
					category4: category && category.length > 0 && category[3] ? category[3].name : "",
					category5: category && category.length > 0 && category[4] ? category[4].name : "",
					list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : '',
					list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : '',
				}

				const gtmData = $product.data('gtm');
				$('body').bootstrap('gtm', {
					type: 'select_item',
					data: gtmData,
				});
			}
		});
	},

	loadTrustPilotWidget: function () {
		$("#search-modal").on('DOMSubtreeModified', '.modal-content', function (e) {
			if (trustpilotWidgetStatus < 300) {
				$(".trustpilot-widget-blank").each(function () {
					try {
						var target = $(this).parents("div.trustpilot-widget")[0];
						if (target) {
							trustpilotWidgetStatus += 1;
							window.Trustpilot.loadFromElement(target);
						}
					} catch(e) {}
				});
			}
		});
	},

	loadTrustPilotWidgetonEienstien: function () {
		$("#maincontent").on('DOMSubtreeModified', '.experience-main', function (e) {
			if (trustpilotWidgetonEienstienStatus < 300) {
				$(".trustpilot-widget-blank").each(function () {
					try {
						var target = $(this).parents("div.trustpilot-widget")[0];
						if (target) {
							trustpilotWidgetonEienstienStatus += 1;
							window.Trustpilot.loadFromElement(target);
						}
					} catch(e) {}
				});
			}
		});
	},

	addToCartTile: function () {
		$(document).on('click', '.add-to-cart-tile', function (e) {
			e.preventDefault();
			if ($(this).parents('.modal-dialog').length) {
				$(this).parents('.modal-dialog').spinner().start();
			} else {
				$.spinner().start();
			}

			$('body').trigger('product:beforeAddToCart', this);

			const $item = $(this);
			const addToCartUrl = $item.closest('.cart-and-ipay').find('.add-to-cart-url').val();

			const form = {
				pid: $item.data('pid'),
				quantity: 1,
				storeId: $item.data('store-id'),
				productVariation: $item.data('product-variation'),
			}

			if (addToCartUrl) {
				$.ajax({
					url: addToCartUrl,
					method: 'POST',
					data: form,
					success: function (data) {
						handlePostCartAdd(data);
						$('body').trigger('product:afterAddToCart', data);
						base.miniCartReportingUrl(data.reportingURL);
						if (data.productVariation) {
							location.reload();
						}
					},
					complete: function () {
						if ($(this).parents('.modal-dialog').length) {
							$(this).parents('.modal-dialog').spinner().stop();
						} else {
							$.spinner().stop();
						}
					},
				});
			}
		});
	},
};

setTimeout(function () {
	jQuery('body').on('click', ".suggestions-wrapper-mini .figure.card-img, .suggestions-wrapper-mini .product-tile-name-wrap , .suggestions-wrapper-mini .product-tile-subtitle", function () {
		const $product = $(this).closest('.product-tile-card').find(".card-body");
		const sku = $product.data('pid');
		const name = $product.find('.card-title.product-tile-name').text().trim() + " - " + $product.find('.card-text.product-tile-subtitle').text().trim();
		const price = $product.data('product-price');
		const brand = $product.data('product-brand');
		const currency = $product.data('product-currency') ? $product.data('product-currency') : "AUD";
		const variant = $product.data('product-variant');
		const index = $($(".card.product-tile-card")).index($(this));
		const category = $product.data('product-category');
		var priceObj = $product.data('product-priceobj');
		const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
		const promotions = $product.data('promotions');
		var promotionList = [];
		if (promotions) {
			for (let i = 0; i < promotions.length; i++) {
				promotionList.push({
					id: promotions[i].id,
					name: promotions[i].name
				})
			}
		}
		const categoryData = {
			category1: category && category.length > 0 && category[0] ? category[0].name : "",
			category2: category && category.length > 0 && category[1] ? category[1].name : "",
			category3: category && category.length > 0 && category[2] ? category[2].name : "",
			category4: category && category.length > 0 && category[3] ? category[3].name : "",
			category5: category && category.length > 0 && category[4] ? category[4].name : "",
			list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : '',
			list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : '',
		}

		const gtmData = $product.data('gtm');
		$('body').bootstrap('gtm', {
			type: 'select_item',
			data: gtmData,
		});
	});

	$(".figure.card-img , .product-tile-name-wrap , .product-tile-subtitle").click(function(){
		const $product = $(this).closest('.product-tile-card').find(".card-body");
		const sku = $product.data('pid');
		const name = $product.find('.card-title.product-tile-name').text().trim() + " - " + $product.find('.card-text.product-tile-subtitle').text().trim();
		const price = $product.data('product-price');
		const brand = $product.data('product-brand');
		const currency = $product.data('product-currency') ? $product.data('product-currency') : "AUD";
		const variant = $product.data('product-variant');
		const index = $($(".card.product-tile-card")).index($(this));
		const category = $product.data('product-category');
		var priceObj = $product.data('product-priceobj');
		const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
		const promotions = $product.data('promotions');
		var promotionList = [];
		if (promotions) {
			for (let i = 0; i < promotions.length; i++) {
				promotionList.push({
					id: promotions[i].id,
					name: promotions[i].name
				})
			}
		}
		const categoryData = {
			category1: category && category.length > 0 && category[0] ? category[0].name : "",
			category2: category && category.length > 0 && category[1] ? category[1].name : "",
			category3: category && category.length > 0 && category[2] ? category[2].name : "",
			category4: category && category.length > 0 && category[3] ? category[3].name : "",
			category5: category && category.length > 0 && category[4] ? category[4].name : "",
			list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : '',
			list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : '',
		}

		const gtmData = $product.data('gtm');
		$('body').bootstrap('gtm', {
			type: 'select_item',
			data: gtmData,
		});
	});
}, 10000);

$(window).ready(function () {
	$('.container').on('click', '.swatches .swatch-link', function (e) {
			e.preventDefault();
			updateTile($(this));
			$(this)
				.parent()
				.parent()
				.find('.swatch-circle')
				.removeClass('selected');
			var a = $(this).data('product-id');
			$(this).parent().parent().find(".swatch-link[data-product-id='" + a + "']").find('.swatch-circle').addClass('selected');
			const qwButtons = $(this)
				.parents()
				.eq(5)
				.find('.qw-buttons');
			const pid = $(this).data('product-id');
			if (qwButtons.length > 0) {
				const productAvailability = $(this).data('product-availablity');
				const isStoreSelected = qwButtons.data('store');
				qwButtons.attr('data-pid', pid);
				const showNotifyMe = $(this).data('show-notify-me');
				if (isStoreSelected) {
					if (productAvailability) {
						if (
							!showNotifyMe &&
							qwButtons.find('.plp-out-of-stock') &&
							qwButtons.find('.plp-out-of-stock').length > 0
						) {
							qwButtons.find('.plp-out-of-stock').addClass('d-none');
						} else {
							qwButtons.find('.notify-me-button').addClass('d-none');
						}
						qwButtons.find('.quick-view-button ').removeClass('d-none');
					} else {
						if (
							!showNotifyMe &&
							qwButtons.find('.plp-out-of-stock') &&
							qwButtons.find('.plp-out-of-stock').length > 0
						) {
							qwButtons.find('.plp-out-of-stock').removeClass('d-none');
						} else if (showNotifyMe) {
							if (qwButtons.find('.notify-me-button') && qwButtons.find('.notify-me-button').length > 0) {
								qwButtons.find('.notify-me-button').removeClass('d-none');
							} else {
								qwButtons.find('.plp-out-of-stock').text('Notify Me');
								qwButtons
									.find('.plp-out-of-stock')
									.removeClass('plp-out-of-stock')
									.addClass('notify-me-button');
								qwButtons.find('.notify-me-button').removeClass('d-none');
							}
						}
						qwButtons.find('.quick-view-button ').addClass('d-none');
					}
				} else {
					qwButtons.find('.quick-view-button ').removeClass('d-none');
				}
			}
			const pdpURL = $(this).data('pdpurl');
			const variantURL = `window.location=${pdpURL}`;
			const parentElement = $(this)
				.parents()
				.eq(2);
			parentElement.find('.product-tile-name-wrap').attr('onclick', variantURL);
			parentElement
				.find('.product-tile-name-wrap')
				.find('.card-title.product-tile-name')
				.attr('href', pdpURL);
			parentElement.find('.card-text.product-tile-subtitle').attr('href', pdpURL);
			parentElement.find('.product-tile-price a').attr('href', pdpURL);

			var colorlst = $(this).find("img").attr('src').split("/");
			var colorValue = colorlst[colorlst.length - 1].trim().replace(".png", "").toUpperCase();
			$(this).closest('.card-body').attr('data-product-variant', colorValue);
			$(this).closest('.card-body').attr('data-variation-id', pid);
	})
	.on('mouseenter', '.swatches .swatch-link', function () {
		const $tile = $(this)
			.parents()
			.eq(5)
			.find('.product-tile-card');
		const imgdata = $(this).data('image');
		if (imgdata && imgdata.url) {
			$tile
				.find('.plp-image-link img')
				.first()
				.attr('src', imgdata.url);
		}
	})
	.on('mouseleave', '.swatches .swatch-link', function () {
		const $selector = $(this)
			.parent()
			.find('.swatch-circle.selected')
			.parent();
		updateTile($selector);
	});

	selectCategoryMattress();
	$('.container').on('click', '.mattress-sizes .size-link', function (e) {
		e.preventDefault();
		updateTileMattress($(this));
		$(this).parent().parent().find('.size-circle').removeClass('selected');
		var a = $(this).data('product-id');
		$(this).parent().addClass('selected');
		const pid = $(this).data('product-id');
		const qwButtons = $(this)
				.parents()
				.eq(5)
				.find('.qw-buttons');
		const variationId = $(this).data('variation-id');
		if (qwButtons.length > 0) {
			qwButtons.attr('data-pid', pid);
			qwButtons.attr('variation-id', variationId);
		}
		const price = $(this).data('price');
		const pdpURL = $(this).data('pdpurl');
		const variantURL = `window.location=${pdpURL}`;
		const parentElement = $(this).parents().eq(3);
		parentElement.find('.mattress-feel-label').addClass('d-none');
		parentElement.find('.mattress-feel-label').each(function() {
			if ($(this).data('size-id') == variationId) {
				$(this).attr('href', pdpURL);
				$(this).removeClass('d-none');
			}
		});
		parentElement.find('.product-tile-name-wrap').attr('onclick', variantURL);
		parentElement.find('.product-tile-name-wrap').find('.card-title.product-tile-name').attr('href', pdpURL);
		parentElement.find('.card-text.product-tile-subtitle').attr('href', pdpURL);
		parentElement.find('.card-text.product-tile-range').attr('href', pdpURL);
		parentElement.find('.product-tile-price a').attr('href', pdpURL);
		parentElement.find('.see-more-sizes a').attr('href', pdpURL);
		parentElement.find('.see-more-sizes-mobile a').attr('href', pdpURL);

		if(parentElement.parent().find('.product-promos-link').length) {
			parentElement.parent().find('.product-promos-link').attr('href', pdpURL);
		}

		const cardBody = $(this).closest('.card-body');
		cardBody.find('.product-tile-price .sales .value').attr('content', price.sales.formatted);
		cardBody.find('.product-tile-price .sales .value').text(price.sales.formatted);
		if (!price.list) {
			cardBody.find('.product-tile-price del').addClass('d-none');
		} else {
			cardBody.find('.product-tile-price del').removeClass('d-none');
			cardBody.find('.product-tile-price .list .value').attr('content', price.list.formatted);
			cardBody.find('.product-tile-price .list .value').html('<span class="sr-only"></span>' + price.list.formatted + '<span class="sr-only"></span>');
		}
		cardBody.attr('data-product-variant', variationId);
		cardBody.attr('data-variation-id', pid);
		$(this).closest('.product-grid-mattress').attr('data-pid', pid);

		if ($(this).attr('data-isInWishList') === 'true') {
			cardBody.find('.wishlistTile .fa').removeClass('fa-heart-o');
			cardBody.find('.wishlistTile .fa').addClass('fa-heart');
			cardBody.find('.wishlistTile').attr('href', $("#removeWishlistURL").val());
		} else {
			cardBody.find('.wishlistTile .fa').removeClass('fa-heart');
			cardBody.find('.wishlistTile .fa').addClass('fa-heart-o');
			cardBody.find('.wishlistTile').attr('href', $("#addWishlistURL").val());
		}
		updateAttributeMattress($(this));

		const productOverlay = $(this).closest('.product-tile-card').find('.mattress-overlays-list');
		productOverlay.each(function() {
			$(this).addClass('d-none');
			if ($(this).data('product-id') === pid) {
				$(this).removeClass('d-none');
			}
		});
	})
	.on('mouseenter', '.mattress-sizes .size-link', function () {
		const $tile = $(this).parents().eq(5).find('.product-tile-card');
		const imgdata = $(this).data('image');
		if (imgdata && imgdata.url) {
			const $firstItem = $tile.find('.plp-image-link img').first();
			$firstItem.attr('src', imgdata.url);
			$firstItem.attr('data-src', imgdata.url);
			$firstItem.attr('data-srcset', imgdata.url);
			$firstItem.attr('srcset', imgdata.url);
		}
	})
	.on('mouseleave', '.mattress-sizes .size-link', function () {
		const $selector = $(this).parent().parent().find('.size-circle.selected');
		updateTileMattress($selector, 'mouseleave');
	});

	if($('.experience-einstein-carousel').length > 0){
		if($('.experience-dynamic-productList').find('.seo-container').length > 0){
			var tempSeoContent = $('.seo-container').html();
			$('.seo-container').html('').css('display','none');
			var carousels = $('.experience-einstein-carousel');
			carousels.parent().last().append('<div class="seo-container">'+tempSeoContent+'</div>');
		}
	}
});
