const {isGTMEnabled} = require('../components/helpers');

module.exports = {
	removeFromCart({
		productSelector = '.product-info-content',
		productDetails = '.remove-product',
		productTileDescriptionSelector = '.line-item-subtitle',
		productPidSelector = 'pid',
		productNameSelector = 'short-name',
	} = {}) {
		if (!isGTMEnabled()) {
			return;
		}

		$('.remove-product').on('click.gtmHandler', function(e) {
			e.preventDefault();
			const gtmData = $product.data('gtm');
			if($(this).hasClass('remove-gift-card')){
				const name = $(this).data('name');
				const uuid = $(this).data('uuid');
				const $gift = $(this).closest(productSelector);
				const price = $gift.find('.line-item-subtitle').text().replace('$', '');
				const from = $(this).data('sender-name');
				const message = $(this).data('message');
				const recipientEmail = $(this).data('recipientemail');
				const recipientName = $(this).data('recipientname');
				const quantity = 1;
				const sku = uuid;

				const data = {
					event: 'remove_from_cart',
					ecommerce: {
						items: [
							{
								name: name,
								uuid: uuid,
								price: price,
								senderName: from,
								recipientEmail: recipientEmail,
								recipientName: recipientName,
								message: message,
							},
						],
					},
				};

				$('.cart-delete-confirmation-btn').on('click.gtmHandler', function(e) {
					e.preventDefault();
					window.dataLayer.push(data);
					$('body').bootstrap('gtm', {
						type: 'removeFromCart',
						data: gtmData,
					});
				});
				return;
			}
			$('.cart-delete-confirmation-btn').off('click');

			const $product = $(this).closest(productSelector);
			const $productDetails = $product.find(productDetails);
			const sku = `${$productDetails.data(productPidSelector)}`;
			const name = `${$productDetails.data(productNameSelector)} - ${$product
				.find(productTileDescriptionSelector)
				.text()
				.trim()}`;

				const quantity = parseInt($product.find('select.amart-quantity').data('pre-select-qty'));
				const price = $product.data('product-price')? $product.data('product-price'): "";
				const currency = $product.data('product-currency')? $product.data('product-currency'): "AUD";
				const brand = $product.data('product-brand')? $product.data('product-brand'): "";
				const variant = $product.data('product-variant')? $product.data('product-variant'): "";
				const index = $($(".card.product-info")).index($(this).closest('.product-info'));
				const category = $product.data('product-category');
				const discount = parseFloat(price * quantity - $product.find('.pricing.line-item-total-price-amount').text().trim().replace(/[$,]/g, '')).toFixed(2);
				const promotions = $product.data('promotions');
				var promotionList = [];
				if (promotions) {
					for (let i = 0; i < promotions.length; i++) {
						promotionList.push(promotions[i].name);
					}
				}
				const categoryData = {
					category1 : category && category.length > 0 && category[0]? category[0].name: "",
					category2 : category && category.length > 0 && category[1]? category[1].name: "",
					category3 : category && category.length > 0 && category[2]? category[2].name: "",
					category4 : category && category.length > 0 && category[3]? category[3].name: "",
					category5 : category && category.length > 0 && category[4]? category[4].name: "",
					list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
					list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
				}

			$('.cart-delete-confirmation-btn').on('click.gtmHandler', function(e) {
				e.preventDefault();
				$('body').bootstrap('gtm', {
					type: 'removeFromCart',
					data: gtmData,
				});

				$('body').bootstrap('gtm', {
					type: 'remove_from_cart',
					data: gtmData,
				});
			});
		});
	},
};
