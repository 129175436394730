const { isGTMEnabled } = require('../components/helpers');

module.exports = {
    cartBeginCheckout({
        
    } = {}) {
        if (!isGTMEnabled()) {
            return;
        }

        jQuery('body').on('click', '#paypal-delivery-popup, .btn-afterpay-express.checkout-btn', function(e){
            $.ajax({
                type: 'GET',
                url: $('#gtmeventdata').val(),
                success: function (response) {
                    if (!response.error) {
                        window.dataLayer.push(function() {
                            this.set('ecommerce', undefined);
                        });
                        window.dataLayer.push(response.data);
                    }
                }
            });
        });
    },
};
