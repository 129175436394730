module.exports = {
	tabs: function() {
		const tabs = $('#product-tabs a');
		tabs.click(e => {
			e.preventDefault();
			$(this).tab('show');
		});

		tabs.keypress(e => {
			const key = e.which;
			if (key === 13) {
				$(this).click();
				return false;
			}
		});
		return false;
	},
	video: function() {
		// Set product video to view
		$('#watch-video').click(function() {
			$('#video-modal iframe').attr('src', `${$(this).data('embed')}?autoplay=1&enablejsapi=1`);
		});
		// Destroy playing video on modal close
		$('#video-modal').on('hidden.bs.modal', function() {
			$('#video-modal iframe').attr('src', '');
		});
	},
};
