'use strict';
var detail = require('base/product/detail');
var base = require('./base');
require('base/components/spinner');

let direction = null;
let lastScrollY = 0;
let curPos = 0;

detail.showSpinner = function () {
    $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
        $.spinner().start();
    });
};

// Remove styling
var resetSticky = function() {
    $('.product-detail-image').removeAttr('style');
};

// Get direction of scroll event
function getDirection() {
    const scrollY = $(document).scrollTop();
    direction = scrollY > lastScrollY ? 'down' : scrollY < lastScrollY ? 'up' : '-';
    lastScrollY = scrollY;
};

// Add Styling
var setCSS = function ($el, position, top) {
    $el.css({
        'position': position,
        'top': top + 'px'
    });
};

// Scroll follow screen
var freeScroll = function ($elm, elementBounding) {
    const parentHeight = $elm.parents('.product-detail__content').innerHeight();
    let top = 0;
    if (direction == 'down') {
        top = curPos + elementBounding.top - $elm.offset().top;
    } else {
        top = parentHeight - elementBounding.height;
    }

    setCSS($elm, 'relative', top);
};

// Stick to top of screen
var stickToTop = function ($elm) {
    let top = $('.header').height() + 15 ;

    if ($('.trust-banner-carousel-container').css('opacity') === '1') {
        top = top + $('.trust-banner-carousel-container').height() ;
    }

    setCSS($elm, 'sticky', top);
};


// Stick to bottom of screen
var stickToBottom = function ($elm) {
    setCSS($elm, 'sticky', window.innerHeight - $elm.innerHeight());
};

// On Scroll Down
var onScrollDown = function($elm) {
    const innerHeight = window.innerHeight;
    const elementBounding = $elm[0].getBoundingClientRect();

    if (!(elementBounding.height > innerHeight)) {
        stickToTop($elm);
    } else if (elementBounding.bottom <= innerHeight) {
        stickToBottom($elm);
    } else if (elementBounding.top <= 0) {
        freeScroll($elm, elementBounding);
    }
};


// On Scroll Up
var onScrollUp = function($elm) {
    const elementBounding = $elm[0].getBoundingClientRect();
    const parentHeight = $elm.parents('.product-detail__content').innerHeight();

    if (elementBounding.top >= elementBounding.height - parentHeight) {
        stickToTop($elm);
    } else {
        freeScroll($elm, elementBounding);
    }
};

window.addEventListener('scroll', function() {
    if($('.product-detail').length) {
        resetSticky();
        if (window.innerWidth < 992) {
            resetSticky();
        } else {
            getDirection();
            let $elm = $('.product-detail-image');
            let top = 0;
            curPos = $(document).scrollTop();
            
            if (direction === 'down') {
                onScrollDown($elm);
            }

            if (direction === 'up') {
                onScrollUp($elm);
            }
        }
    }
});

detail.addToCart = base.addToCart;

module.exports = detail;
