const formValidation = require('sfraScripts/components/formValidation');
const shippingAddressHelper = require('../components/shippingAddressHelper');

module.exports = {
	open: function(){
        $(document).on('click', '.delivery-option-btn', function(e) {
			e.preventDefault();
			const selectedType = $(this).data('type');
			const itemOptionSelector = 'input.delivery-option--selector[value="' + selectedType + '"]';

			if ($(itemOptionSelector).length > $(itemOptionSelector + ':checked').length) {
				// if not all the products have selected delivery option checked, show confirm window
				$('#cartDeliveryPickupAddressModal').attr('data-change-to', selectedType);
				$('body').addClass('modal-open');
				$('#cartDeliveryPickupAddressModal').modal('show');
			} else if (selectedType === 'delivery') {
				// if all products are delivery, show estimator
				$('body').addClass('modal-open');
				$('#cartDeliveryAddressModal').modal('show');
				$('#estimate-input').attr('data-result', $('#delivery-to-btn').attr('data-preloadaddress'));
				const preloadAddress = JSON.parse($('#delivery-to-btn').attr('data-preloadaddress'));
				if (preloadAddress && preloadAddress.address1 !== '') {
					if (preloadAddress && preloadAddress.address1) {
						$('#estimate-input').val(preloadAddress.address1);
					}
					const isAddressValid = shippingAddressHelper.validateDeliveryAddress($('#estimate-input').val(), 'cart');
					if (isAddressValid) {
						$('.delivery-estimate-btn').removeAttr('disabled');
						$('.delivery-estimate-btn').trigger('click');
					}
				} else {
                    $('#estimate-input').val('');
                    $('.delivery-estimate-results').html('');
                    $('.delivery-estimate-btn').attr('disabled', 'disabled');
                }
			} else if (selectedType === 'pickup') {
				// if all products are pickup and a store is not selected, show storeselect-modal
				if ($(this).hasClass('no-store-selected')) {
					$('body').addClass('modal-open');
					window.storeSelectFlyout.openStoreSelectFlyout();
					$(this).parent().addClass('active');
				}
			}
		});
    
        $(document).on('click', '#pickupToDeliveryYesBTN', function(e) {
            $.spinner().start();
			// update all pickup products to delivery
			const selectedType = $(this).closest('.modal').attr('data-change-to');
			const itemsToChangeQ = $('input.delivery-option--selector[value="' + selectedType + '"]:not(:checked)').length;
			let itemsToChangeCounter = 0;

			$('input.delivery-option--selector:checked').each(function() {
				const $currentOption = $(this);
				if ($currentOption.val() !== selectedType) {
					const name = $currentOption.prop('name');
					const $target = $("input[name='" + name + "']:not(:checked)");
					if ($target.length) {
						const $checkoutBtn = $('.checkout-btn');
						$checkoutBtn.addClass('disabled');

						$.ajax({
							url: $target.closest('.delivery-option').data('url'),
							type: 'POST',
							dataType: 'json',
							data: {
								method: selectedType,
								uuid: $currentOption.closest('.delivery-option').data('lineitem'),
							},
							success: function(response) {
								if (response.checkoutEnabled) {
									$checkoutBtn.removeClass('disabled');
								} else {
									$('.js-delivery-option').removeClass('active');
									$('.js-delivery-option .line-item').addClass('d-none');
									const $deliveryBtnText = $('.js-delivery');
									$deliveryBtnText.text($deliveryBtnText.data('default-text'));

									const $shippingCost = $('.shipping-cost');
									if (selectedType === 'delivery' && $shippingCost.html() === '$0.00') {
										$shippingCost.html('$ &mdash;');
									}
								}
								$target.prop('checked', true);
								itemsToChangeCounter++;
								if (itemsToChangeCounter === itemsToChangeQ) {
									if (selectedType === 'pickup') {
										window.location.reload();
									} else {
										$.spinner().stop();
									}
								}
							},
							error: function() {
								itemsToChangeCounter++;
								if (itemsToChangeCounter === itemsToChangeQ) {
									if (selectedType === 'pickup') {
										window.location.reload();
									} else {
										$.spinner().stop();
									}
								}
							},
						});
						$('.delivery-option-btn:not([data-type="' + selectedType + '"])').parent().removeClass('active');
						$('.js-pickup-option .line-item').addClass('d-none');
					}
				}
			});
			$.spinner().stop();
            $('#cartDeliveryPickupAddressModal').modal('hide');

			if (selectedType === 'delivery') {
				$('body').addClass('modal-open');
				$('#cartDeliveryAddressModal').modal('show');
			} else if (selectedType === 'pickup') {
				$('body').addClass('modal-open');
				if ($('.delivery-option-btn.no-store-selected').length) {
					window.storeSelectFlyout.openStoreSelectFlyout();
				} else {
					$('.js-pickup-option').addClass('active');
				}
			}
        });

        $(document).on('click', '#pickupToDeliveryNoBTN', function(e) {
			const selectedType = $(this).closest('.modal').attr('data-change-to');
            $('#cartDeliveryPickupAddressModal').modal('hide');
            if(selectedType === 'delivery') {
				$('body').addClass('modal-open');
				$('#cartDeliveryAddressModal').modal('show');
			}
        });

		$(document).on('click', '#delivery-warning-popup', function() {
			$('#cartDeliveryAddressAfterpayModal').modal('show');
		});

		$(document).on('click', '#paypal-delivery-popup', function() {
			$('#cartDeliveryAddressPaypalModal').modal('show');
		});

		$(document).on('click', '.btn-afterpay-checkout', function() {
			$('#cartDeliveryAddressAfterpayModal').modal('hide');
		});

		$(document).on('click', '.change-address-option', function() {
			$('#cartDeliveryAddressAfterpayModal').modal('hide');
			$('#cartDeliveryAddressPaypalModal').modal('hide');	
			$('.js-delivery-option .delivery-option-btn').trigger('click');
		});
		
	},
};
