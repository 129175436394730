require('bootstrap/js/src/collapse.js');

function collapsibleInit() {
	// Initialise all collapsibles on load
	$('.collapsible').each(function() {
		const $el = $(this);

		// hide search results filters by default on mobile
		if ($el.hasClass('filter-master') || $el.hasClass('autocollapse')) {
			if ($(window).width() < 992) {
				$el.children('div.collapse').removeClass('show');
			} else {
				$el.children('div.collapse').addClass('show');
			}
			$el.addClass('initialised');
		}

		// Add css icon and accessibility
		if ($el.children('div.collapse').hasClass('show')) {
			$el.find('.collapsible-title').attr('aria-expanded', 'true');
			$el.addClass('open');
			// add aria-controls
		} else {
			$el.find('.collapsible-title').attr('aria-expanded', 'false');
		}
	});

	$('.collapse-action').on('click', function() {
		if ($(this).attr('aria-expanded') === 'true') {
			const collapse = $(this);
			setTimeout(function () {
				collapse.attr('aria-expanded', 'false');
				collapse
				.closest('.rounded-box')
				.children('div.collapse')
				.collapse('hide');
			}, 350);
		}
	});

	// Watch collapsible changes
	$('.collapsible-title').on('click', function() {
		if ($(this).attr('aria-expanded') === 'true') {
			// close this collapsible
			$(this).attr('aria-expanded', 'false');
			$(this)
				.closest('.collapsible')
				.removeClass('open');
			$(this)
				.closest('.collapsible')
				.children('div.collapse')
				.collapse('hide');
		} else {
			// open this collapsible
			$(this).attr('aria-expanded', 'true');
			$(this)
				.closest('.collapsible')
				.addClass('open');
			$(this)
				.closest('.collapsible')
				.children('div.collapse')
				.collapse('show');
		}
		localStorage.setItem($(this).parent().next(".card-body").attr('id'), $(this).attr('aria-expanded'));
	});

	// Watch accordian changes on content pages
	$('.faq-text a').on('click', function() {
		if ($(this).closest('.hr-line').hasClass('open')) {
			// close this collapsible
			$(this).attr('aria-expanded', 'false');
			$('.hr-line')
				.removeClass('open');
			$(this)
				.closest('.hr-line')
				.children('div.collapse')
				.collapse('hide');
				return false;
		} else {
			// open this collapsible
			$('.hr-line')
				.removeClass('open');
			$(this).attr('aria-expanded', 'true');
			$(this)
				.closest('.hr-line')
				.addClass('open');
			$(this)
				.closest('.hr-line')
				.children('div.collapse')
				.collapse('show');
				return false;
		}
	});


	// Search page filter apply button action
	$('.search-results #search-filter-apply').on('click', function() {
		// close search collapsible
		$(this)
			.closest('.collapsible')
			.find('.collapsible-title')
			.attr('aria-expanded', 'false');
		$(this)
			.closest('.collapsible')
			.removeClass('open');
		$(this)
			.closest('.collapsible')
			.children('div.collapse')
			.collapse('hide');
		// scroll top
		let menuTopOffset = 95;
		if ($(window).width() < 992) {
			menuTopOffset = 50;
		}
		$('html, body').animate(
			{scrollTop: $('.search-results .filter-master').offset().top - menuTopOffset - 15},
			400,
		);
	});
}

module.exports = {
	collapsibleInit: collapsibleInit,
};
