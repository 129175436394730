const { isGTMEnabled } = require('../components/helpers');

module.exports = {
    viewCartEvent({
        productSelector = '.product-info-content',
        productDetails = '.remove-product',
        productTileDescriptionSelector = '.line-item-subtitle',
        productPidSelector = 'pid',
        productNameSelector = 'short-name',
    } = {}) {
        if (!isGTMEnabled()) {
            return;
        }

        var productList = [];
        var currency;
        $(".card.product-info").each(function (e) {
            const $product = $(this).find('.product-info-content.flex-column');

            const $productDetails = $product.find(productDetails);
            const sku = `${$productDetails.data(productPidSelector)}`;
            const name = `${$productDetails.data(productNameSelector)} - ${$product
                .find(productTileDescriptionSelector)
                .text()
                .trim()}`;

            const quantity = parseInt($product.find('select.amart-quantity').data('pre-select-qty'));
            const price = $product.data('product-price') ? $product.data('product-price') : "";
            currency = $product.data('product-currency') ? $product.data('product-currency') : "AUD";
            const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
            const minorCategory = $product.data('product-minor-category') ? $product.data('product-minor-category') : "";
            const variant = $product.data('product-variant') ? $product.data('product-variant') : "";
            const index = e;
            const category = $product.data('product-category');
            const priceObj = $product.data('product-priceobj');
            const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
            const couponCodes = $product.data('couponcodes');
            const promotions = $product.data('promotions');
            var promotionList = [];
            if (promotions) {
                for (let i = 0; i < promotions.length; i++) {
                    promotionList.push({
                        id: promotions[i].id,
                        name: promotions[i].name
                    })
                }
            } else if(couponCodes) {
                for (let i = 0; i < couponCodes.length; i++) {
                    promotionList.push({
                        id: couponCodes[i].couponCode
                    });
                }
            }

            const categoryData = {
                category1: category && category[0] ? category[0].name : "",
                category2: category && category[1] ? category[1].name : "",
                category3: category && category[2] ? category[2].name : "",
                category4: category && category[3] ? category[3].name : "",
                category5: category && category[4] ? category[4].name : "",
                list_id: category && category[category.length - 1] ? category[category.length - 1].id : '',
                list_name: category && category[category.length - 1] ? category[category.length - 1].name : '',
            }

            var data = {
                item_id: sku,
                item_name: name,
                affiliation: "Amart Online",
                  coupon: promotionList.length > 0 ? promotionList : '',
                  discount: parseFloat(discount).toFixed(2),
                  index: index,
                  item_brand: brand,
                  item_category: categoryData.category1,
                  item_category2: categoryData.category2,
                  item_category3: categoryData.category3,
                  item_category4: categoryData.category4,
                  item_category5: categoryData.category5,
                  item_list_id: categoryData.list_id,
                  item_list_name: categoryData.list_name,
                  item_variant: minorCategory,
                  item_colour: variant ? "Finish - " + variant : '',
                  location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                  price: price,
                  quantity: quantity
            }

            productList.push(data);
        });

        var grandTotal = $('.text-right.grand-total').text().replace('$', '');
        if (grandTotal === '-') {
            grandTotal = $('.text-right.sub-total').text().replace('$', '');
        }

        var gtmData = {
            event: 'view_cart',
            ecommerce: {
                currency: currency,
                value: grandTotal,
                items: productList
            }
        }

        if(productList.length > 0){
            window.dataLayer.push(function() {
                this.set('ecommerce', undefined);
            });
            window.dataLayer.push(gtmData);
        }
    },
};
