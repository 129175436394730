function validateDeliveryAddress(inputString, page) {
	let disallowAddresses = $('#disallowedAddress').val();
	let isValid = true;
	if (disallowAddresses && disallowAddresses !== '') {
		disallowAddresses = disallowAddresses.split(',');

		if (disallowAddresses.length > 0) {
			for (let i = 0; i < disallowAddresses.length; i++) {
				const disallowedAddress = disallowAddresses[i].toLowerCase();
				if (inputString.toLowerCase().indexOf(disallowedAddress) > -1) {
					$('.delivery-address-error').removeClass('d-none');
					if (page === 'cart') {
						$('.delivery-estimate-btn').attr('disabled', true);
					} else {
						$('.delivery-address-error').parent().find('#shippingAddressOnedefault').addClass('is-invalid');
					}
					isValid = false;
					break;
				} else {
					$('.delivery-address-error').addClass('d-none');
					if (page === 'cart') {
						$('.delivery-estimate-btn').removeAttr('disabled');
					} else {
						$('.delivery-address-error').parent().find('#shippingAddressOnedefault').removeClass('is-invalid');
					}
					isValid = true;
				}
			}
		}
	}
	return isValid;
}

function validateFormAddress(form) {
	var validForm = true;
	form.find('input').each(function(){
		if($(this).prop('required') && $(this).val() === '' && $(this).closest('.d-none').length === 0){
			validForm = false;
			return;
		}
	});
	return validForm;
}

module.exports = {
	validateDeliveryAddress: validateDeliveryAddress,
	validateFormAddress: validateFormAddress,
};
