const { isGTMEnabled } = require('../components/helpers');

function getDataForShipping(shipping_tier) {
    var grandTotal = $('.text-right.grand-total').text().replace('$', '');
    $.ajax({
        type: 'GET',
        url: $('#gtmeventdata').val(),
        success: function (response) {
            if (!response.error) {
                window.dataLayer.push(function () {
                    this.set('ecommerce', undefined);
                });
                response.data.event = 'add_shipping_info';
                response.data.ecommerce.shipping_tier = shipping_tier;
                window.dataLayer.push(response.data);
            }
        },
        error: function (error) {

        }
    });
}

module.exports = {
    addShippingInfoEvent({

    } = {}) {
        if (!isGTMEnabled()) {
            return;
        }

        jQuery('body').on('click', '#deliveryConfirmBTN', function (e) {
            var shipping_tier = $("body").find('#cartDeliveryAddressModal .delivery-options .delivery-option.selected .delivery-option--selector .pl-2.my-auto').html().trim();
            getDataForShipping(shipping_tier);
        });

        jQuery('body').on('click', '.js-pickup-option', function (e) {
            $("#cartDeliveryPickupAddressModal").addClass('openCsPopup');
        });

        jQuery('body').on('click', '#cartDeliveryPickupAddressModal.openCsPopup #pickupToDeliveryYesBTN', function (e) {
            var shipping_tier = 'pickup';
            getDataForShipping(shipping_tier);
        });

        jQuery('body').on('click', '.js-delivery-option', function (e) {
            $("#cartDeliveryPickupAddressModal").removeClass('openCsPopup');
        });
    },
};
