'use strict';
require('bootstrap/js/src/modal.js');
require('bootstrap/js/src/collapse.js');

const toast = require('../components/toast-message');
const carousel = require('../components/tab-carousel'); // to apply trust banner init
require('base/components/spinner');
var base = require('./base');
var quickView = require('base/product/quickView');
var pdpDetails = require('./details');

function listenForAdd() {
	const addButton = $('#mto-modal .modal-content').find('.add-to-cart-global');
	addButton.off('click');
	addButton.attr('id', 'mto-add');
	addButton.removeClass('add-to-cart-global');
	addButton.click(function() {
		$.spinner().start();
		const pid = $(this).data('pid');
		const addToCartUrl = $(this)
			.siblings('.add-to-cart-url')
			.val();

		const quantity = $(this)
			.closest('.modal-footer')
			.find('.quantity-select')
			.val();

		if (addToCartUrl) {
			$.ajax({
				url: addToCartUrl,
				method: 'POST',
				data: {
					pid: pid,
					quantity: quantity,
				},
				success: function(data) {
					$.spinner().stop();
					$('#mto-modal').modal('hide');
					$('#mto-modal').on('hidden.bs.modal', function() {
						toast.success(data.message);
					});
				},
				error: function() {
					$.spinner().stop();
					$('#mto-modal').modal('hide');
					$('#mto-modal').on('hidden.bs.modal', function() {
						toast.error('Customisation tool error, please try again.');
					});
				},
			});
		}
	});
}

function listenForVariation() {
	$('.color-attribute').click(function(e) {
		e.preventDefault();
		const url = $(this).data('url');
		updateModal(url);
	});
}


function updateModal(url) {
	$.spinner().start();
	$('#mto-modal').addClass('below-spinner');
	$.ajax({
		url: url,
		type: 'get',
		dataType: 'html',
		success: function(data) {
			$.spinner().stop();
			$('#mto-modal').removeClass('below-spinner');
			const res = JSON.parse(data);
			if (res.renderedTemplate) {
				// Clear old data, in mtoModal.isml
				$('#mto-modal .modal-body').remove();
				$('#mto-modal .modal-footer').remove();
				// Append MTO option info, from quickView.isml
				$('#mto-modal .modal-content').append(res.renderedTemplate);
                $('#mto-modal .mto-notice').remove();                
				listenForAdd();
				listenForVariation();
				$('#mto-modal')
					.on('shown.bs.modal', carousel.trustBannerCarousel)
					.modal('show');
			} else {
				$('#mto-modal').removeClass('below-spinner');
				toast.error('Customisation tool error, please try again.');
				$.spinner().stop();
			}
		},
		error: function(e) {
			$.spinner().stop();
			$('#mto-modal').removeClass('below-spinner');
			toast.error('Customisation tool error, please try again.');
		},
	});
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#mto-modal').length !== 0) {
        $('#mto-modal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link" href=""></a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';

    var html = '<div class="modal fade mto-modal" id="mto-modal">';
    html += '  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">';
    html += '    <div class="modal-content">';
    html += '      <div class="modal-header">';
    html += '        <div class="d-flex flex-column w-100">';
    html += '          <div class="d-flex px-3 py-3 justify-content-between">';
    html += '            <h4 class="modal-product-name"></h4>';    
    html += '            <div class="d-flex px-3 py-3 justify-content-between">';    
    html += '              <div class="modal-title modal-title-d"></div>';
    html += '              <button type="button" class="close" data-dismiss="modal" aria-label="Close">';
    html += '                <span aria-hidden="true">&times;</span>';
    html += '              </button>';
    html += '            </div>';    
    html += '          </div>';
    html += '          <div class="modal-title modal-title-m"></div>'
    html += '        </div></div></div>';
    
    $('body').append(html);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
//    $('.modal-body').spinner().start();
    $.spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'html',
        success: function (data) {
            $.spinner().stop();
            const res = JSON.parse(data);
            if (res.renderedTemplate && Object.keys(res.product).length) {
                // Clear old data, in mtoModal.isml
                $('#mto-modal .modal-body').remove();
                $('#mto-modal .modal-footer').remove();
                $('#mto-modal .modal-title-d').html('<a class="full-pdp-link btn btn-primary hidden-xs-down" href="'+ res.productUrl +'">View Full Details</a>');
                $('#mto-modal .modal-title-m').html('<a class="full-pdp-link btn btn-primary d-sm-none" href="'+ res.productUrl +'">View Full Details</a>');
                $('#mto-modal .modal-product-name').html(res.product.productName);
                
                // Append MTO option info, from quickView.isml
                $('#mto-modal .modal-content').append(res.renderedTemplate);
                $('#mto-modal .mto-notice').remove();                
                listenForAdd();
                listenForVariation();
                $('#mto-modal')
                    .on('shown.bs.modal', carousel.trustBannerCarousel)
                    .modal('show');

                // Initialise all collapsibles on load
                $('#mto-modal .collapsible').each(function() {
                    const $el = $(this);

                    // hide search results filters by default on mobile
                    if ($el.hasClass('filter-master') || $el.hasClass('autocollapse')) {
                        if ($(window).width() < 992) {
                            $el.children('div.collapse').removeClass('show');
                        } else {
                            $el.children('div.collapse').addClass('show');
                        }
                        $el.addClass('initialised');
                    }

                    // Add css icon and accessibility
                    if ($el.children('div.collapse').hasClass('show')) {
                        $el.find('.collapsible-title').attr('aria-expanded', 'true');
                        $el.addClass('open');
                        // add aria-controls
                    } else {
                        $el.find('.collapsible-title').attr('aria-expanded', 'false');
                    }
                });                    
            } else {
                $.spinner().stop();
                toast.error('Customisation tool error, please try again.');
            }

            /*
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('.modal-body').empty();
            $('.modal-body').html(parsedHtml.body);
            $('.modal-footer').html(parsedHtml.footer);
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            $('#quickViewModal').modal('show');
            */

        },
        error: function () {
            $.spinner().stop();
            toast.error('Customisation tool error, please try again.');
        }
    });
}

quickView.showQuickview = function () {
    $('body').off('click', '.quickview');
    $('body').on('click', '.quickview', function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        var selectedValueUrl = $(this).closest('a.quickview').attr('href');
        $(e.target).trigger('quickview:show');
        getModalHtmlElement();
        fillModalElement(selectedValueUrl);
        return false;
    });
};

quickView.mtoHandler = function(){
    // Watch collapsible changes
    $(document).on('click', '#mto-modal .collapsible-title', function(){
        if ($(this).attr('aria-expanded') === 'true') {
            // close this collapsible
            $(this).attr('aria-expanded', 'false');
            $(this)
                .closest('.collapsible')
                .removeClass('open');
            $(this)
                .closest('.collapsible')
                .children('div.collapse')
                .collapse('hide');
        } else {
            // open this collapsible
            $(this).attr('aria-expanded', 'true');
            $(this)
                .closest('.collapsible')
                .addClass('open');
            $(this)
                .closest('.collapsible')
                .children('div.collapse')
                .collapse('show');
        }
    });
};

quickView.mtoCollapse = function(){
    // Watch collapsible changes
    $(document).off('click','#editWishlistProductModal .collapsible-title' ).on('click', '#editWishlistProductModal .collapsible-title', function(){
        if ($(this).attr('aria-expanded') === 'true') {
            // close this collapsible
         
            $(this).attr('aria-expanded', 'false');
            $(this)
                .closest('.collapsible')
                .removeClass('open');
            $(this)
                .closest('.collapsible')
                .children('div.collapse')
                .collapse('hide');
        } else {
            // open this collapsible
            $(this).attr('aria-expanded', 'true');
           
            $(this)
                .closest('.collapsible')
                .addClass('open');
            $(this)
                .closest('.collapsible')
                .children('div.collapse')
                .collapse('show');
        }
    });
};

quickView.mtoPopup = function(){
    $(document).on('click', '#mto-modal .product-mto-option', function(e){            
        const el = $(this);
        fillModalElement(el.data('url'));
    });
};

quickView.hideDialog = function () {
    $('body').on('product:afterAddToCart', function () {
        $('#quickViewModal').modal('hide');
    });
};

quickView.selectAttrMattress = function () {
    $('body').on('click', '.product-quickview .custom-options li', function(e){
        e.preventDefault();
        if($(this).parent().hasClass('mattress-attribute')) {
          if ($(this).hasClass('selected')) { return; }
          var $productContainer = $(this).closest('.product-detail');
          pdpDetails.attributeSelect($(this).attr('data-url'), $productContainer);
          $(this).parent().find('li').removeClass('selected');
          $(this).addClass('selected');
        }
    });

    $('body').on('change', '.quantity-select-mattress', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.modal-content').find('.product-quickview');
        }

        if ($('.bundle-items', $productContainer).length === 0) {
            pdpDetails.attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                $productContainer);
        }
    });
};

quickView.addToCart = base.addToCart;


module.exports = quickView;
