require('hc-offcanvas-nav/dist/hc-offcanvas-nav');

function debounce(func, wait, immediate) {
	let timeout;
	return function() {
		const context = this,
			args = arguments;
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

function addMobileNavigationStyle() {
	const url = $('#mobileMenuCSS').val();
	if (window.innerWidth < 1279) {
		$(`link[rel=stylesheet][href~="${url}"]`).remove();
		$('head').append(`<link rel="stylesheet" href="${url}" type="text/css" />`);
	} else {
		$('.hc-offcanvas-nav .hc-nav.hc-nav-1').addClass('d-none');
		$('.hc-offcanvas-nav .hc-nav.hc-nav-1').removeClass('d-block');
		if ($(`link[rel=stylesheet][href~="${url}"]`).length > 0) {
			$(`link[rel=stylesheet][href~="${url}"]`).remove();
		}
	}
}

// Show the first category (has children) - level 1 when open mobileNav.
function showFirstCategory() {
	var $itemLv1 = $('.hc-nav.hc-nav-1 > .nav-item.open-first');
	if ($itemLv1.length) {
		$itemLv1 = $($itemLv1[0]);
		$itemLv1.addClass('level-open');
		if ($itemLv1.children('input[type="checkbox"]').length) {
			$itemLv1.children('input[type="checkbox"]').prop( "checked", true );
		}
	}
}

module.exports = function() {
	// Clone desktop menu
	const $clonedMenu = $('header .mobile-menu-data')
		.clone(false)
		.removeClass('d-none');
	const $toggle = $('.header-mobile-container .navbar-toggle');

	// Init mobile menu
	const $mobileNav = $clonedMenu.hcOffcanvasNav({
		maxWidth: 1279,
		customToggle: $toggle,
		insertClose: false,
		insertBack: true,
		labelBack: '',
		closeOnClick: false,
	});

	// Set close button listener
	$('.btn-close-mobile-nav, .top-store-selector .nav-link--storeselect').on('click', function() {
		$mobileNav.close();
		if ($('.hc-offcanvas-nav .hc-nav.hc-nav-1').hasClass('d-block')) {
			$('.hc-offcanvas-nav .hc-nav.hc-nav-1').addClass('d-none');
			$('.hc-offcanvas-nav .hc-nav.hc-nav-1').removeClass('d-block');
		}
	});

	// 2020.01.20 Michael Added '.nav-back' label
	$('.nav-back').each(function() {
		const parentName = $(this)
			.parents('.nav-wrapper:first')
			.siblings('.nav-item')
			.text();
		const html = "<strong style='margin-left: 2em;'>" + parentName + '</strong>' + '<span></span>';
		$(this)
			.children('a:first')
			.html(html);
	});

	// Close mobileNav on window resize and throttle resize call every 250ms
	const closeNavOnResize = debounce(function() {
		if ($mobileNav && $mobileNav.length > 0 && $mobileNav.isOpen()) {
			$mobileNav.close();
			if ($('.hc-offcanvas-nav .hc-nav.hc-nav-1').hasClass('d-block')) {
				$('.hc-offcanvas-nav .hc-nav.hc-nav-1').addClass('d-none');
				$('.hc-offcanvas-nav .hc-nav.hc-nav-1').removeClass('d-block');
			}
		}
		addMobileNavigationStyle();
	}, 250);
	$(window).on('resize', closeNavOnResize);
	addMobileNavigationStyle();

	$('.col-mobile-toggle .navbar-toggle').on('click', function() {
		if (!$('.hc-offcanvas-nav .hc-nav.hc-nav-1').hasClass('d-block')) {
			$('.hc-offcanvas-nav .hc-nav.hc-nav-1').addClass('d-block');
			$('.hc-offcanvas-nav .hc-nav.hc-nav-1').removeClass('d-none');
		}
	});

	showFirstCategory();
	$mobileNav.on('open close', function() {
		showFirstCategory();
	});

	// Revert the style of menu items when a menu is selected.
	$('body').on('change', '.hc-nav.hc-nav-1 > .nav-item > input[type="checkbox"]', function() {
		var $sibling = $(this).parent('.nav-item').siblings();
		$sibling.removeClass('level-open').find('input[type="checkbox"]').prop( "checked", false );
		$sibling.find('.nav-item').removeClass('level-open').find('input[type="checkbox"]').prop( "checked", false );
	});

	// Update the style of mobileNav when the store has selected or no.
	var isStore = $('.hc-offcanvas-nav .hc-nav.hc-nav-1').find('.storeselect-name--not-selected').length;
	if (!isStore) {
		$('.hc-offcanvas-nav').addClass('has-store');
	} else {
		$('.hc-offcanvas-nav').removeClass('has-store');
	}
};
