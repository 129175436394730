const toastMessage = require('../js/components/toast-message');
var approachingDiscounts = require('./components/approaching-discounts');
var customDropDown = require('../js/customSelect');
var swatchPdp = require('../js/product/swatchPdp');

function handlePostCartAdd(response) {
    var miniQuantity = $('.minicart .minicart-quantity');
    if (!miniQuantity.hasClass('show')) {
        miniQuantity.addClass('show');
    }
    miniQuantity.text(response.quantityTotal);
    // const messageType = response.error ? 'alert-danger' : 'alert-success';

    // if ($('.add-to-cart-from-tile-messages').length === 0) {
    //     $('body').append('<div class="add-to-cart-from-tile-messages"></div>');
    // }

    // $('.add-to-cart-from-tile-messages').append(
    //     `<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${response.message}</div>`,
    // );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}

function addRatingDiv(){
    var element = document.getElementsByClassName("trustpilot-widget");
    for(var i=0; i<element.length; i++) {
        window.Trustpilot.loadFromElement(element[i]);
    }
};

function parseHtml(html) {
	const $html = $('<div>').append($.parseHTML(html));

	const body = $html.find('.product-quickview');
	const footer = $html.find('.modal-footer').children();

	return {body: body, footer: footer};
}

function asyncLoad(productObject) {
    if (productObject.latitudeMerchantId && productObject.latitudeMerchantUrl) {
        var priceObject = JSON.parse(productObject.priceObj);
        var salesPrice = priceObject.max ? priceObject.max.sales.value : priceObject.sales.value;
        window.LatitudeCheckout = {
            "merchantId": productObject.latitudeMerchantId,
            "page": "product",
            "container": "latitude-banner-container",
            "containerClass": "latitude-product",
            "currency": "AUD",
            "minAmount": "",
            "layout": "inversed",
            "paymentOption": "equal",
            "promotionMonths": 50,
            "product": {
                "id": productObject.pid,
                "name": productObject.name,
                "category": "null",
                "price": salesPrice,
                "sku": productObject.pid
        }};

        var curr = document.createElement("script");
        curr.type = "text/javascript";
        curr.async = true;
        curr.src = productObject.latitudeMerchantUrl;
        var scr = document.getElementsByTagName("script")[0];
        scr.parentNode.insertBefore(curr, scr);

        var classname = document.getElementsByClassName("details-button");
        for (var i = 0; i < classname.length; i++) {
            classname[i].addEventListener('click', asyncLoad, false);
        }
    }
}

function updateClassPlpQuickViewModal() {
    var heightDataTop = $('.product-data-top').height();
    var heightDataBottom = $('.product-data-bottom').height();
    if((heightDataTop + heightDataBottom) > $('.product-quickview').height()) {
        $("#plpQuickViewModal").addClass('view-small-height');
    } else {
        $("#plpQuickViewModal").removeClass('view-small-height');
    }
}

jQuery('body').on(
	'click',
	'.qw-buttons .quick-view-button, .add-to-cart-style-store-locator, #plpQuickViewModal .select-store-button',
	function(e) {
    e.preventDefault();
    if ($(this).parents('.modal-dialog').length) {
        $(this).parents('.modal-dialog').spinner().start();
    } else {
        $.spinner().start();
    }

    const $item = $(this).parent();
    if ($item.data('store')) {
        if ($(this).hasClass('is-mattress-add-to-cart')) {
            return;
        }
        const addToCartUrl = $item.find('#addtocarturl').val();

        const form = {
            pid: $item.attr('data-pid'),
            quantity: 1,
            storeId: $item.find('#storeid').val(),
        }
        var showApproachingPromotionMsg = $(this).closest('.plp-product').find('.product-promotions').data('showapproachingpromotionmsg');
        $.ajax({
            type: "POST",
            url: addToCartUrl,
            data: form,
            success: function (response) {
                $.spinner().stop();
                handlePostCartAdd(response);
                let htmlToAppend = '';
                if (response.cart.approachingDiscounts.length > 0) {
                    for (let i = 0; i < response.cart.approachingDiscounts.length; i++) {
                        var approachingDiscount = response.cart.approachingDiscounts[i];
                        if (approachingDiscount.showApproachingPromotionMsg === true && showApproachingPromotionMsg === true) {
                            htmlToAppend += '<div class="approaching-discounts-inner"><div class="single-approaching-discount text-center">';
                            htmlToAppend += '<span class="single-approaching-discount-message">' + approachingDiscount.discountMsg + '</span>';
                            htmlToAppend += '<div class="group-progress"><p class="progress" data-conditionthreshold="'+ approachingDiscount.conditionThreshold +'" data-merchandisetotal="'+ approachingDiscount.merchandiseTotal +'" data-distancefromconditionthreshold="'+ approachingDiscount.distanceFromConditionThreshold +'"><span class="progress-bar"></span><span class="icon"><span class="fa fa-check"></span></span></p></div>';
                            htmlToAppend += '<span class="single-approaching-discount-disclaimer">' + approachingDiscount.disclaimer + '</span>' || '';
                            htmlToAppend += '</div></div>';
                        }
                    }
                    $('.approaching-discounts').empty().append(htmlToAppend);
                    approachingDiscounts.loadProgress();
                } else {
                    $('.approaching-discounts').empty().append(htmlToAppend);
                }
                $("#addToCartMessage-modal").modal('show');
            },
            error: function (error) {
                $.spinner().stop();
            }
        });
    } else {
        window.storeSelectFlyout.openStoreSelectFlyout();
        document.getElementById('storeAddress').focus();
        document.getElementById("storeAddress").select();
        $("<input type='hidden' />")
     .attr("id", "product-id")
     .attr("value", $item.data('pid'))
     .prependTo("#storeselect-modal");
     $("<button class='d-none add-to-cart'></button>")
     .prependTo("#storeselect-modal");
     if ($item.data('is-quickview')) {
        $("<input type='hidden' />")
        .attr("id", "plpQuickView")
        .attr("value", $item.data('is-quickview'))
        .prependTo("#storeselect-modal");
     }
        $.spinner().stop();
    }
});

jQuery('body').on('click', '#addToCartMessage-modal .modal-button-vertical.btn-group button:last', function(e){
    e.preventDefault();
    $("#addToCartMessage-modal").modal('hide');
});

jQuery('body').on('click', ".details-button", function (e) {
    e.preventDefault();
    $.spinner().start();
    const quickviewurl = $(this).parent().find('#getquickviewurl').val();
    const latitudeMerchantUrl = $(this).parent().find('#latitudeMerchantUrl').val();
    var $cartBody = $(this).closest('.plp-product');
    var $selectedCircle = $cartBody.find('.size-circle.selected');
    var variationId;
    if ($selectedCircle.length) {
        variationId = $selectedCircle.find('.size-link').data('variation-id');
    } else {
        variationId = $(this).parent().attr('variation-id');
    }
    const form = {
        pid: $(this).parent().attr('data-pid'),
        uuid: $(this).parent().data('uuid'),
        defaultMattressSize: variationId
    }
    var productObject = {
        pid: $(this).parent().attr('data-pid'),
        name: $(this).parent().attr('data-product-name'),
        priceObj: $(this).parent().attr('data-product-priceobj'),
        latitudeMerchantId: $(this).parent().attr('data-latitude-merchant-id'),
        latitudeMerchantUrl: latitudeMerchantUrl
    };
    asyncLoad(productObject);

    $.ajax({
        type: "get",
        url: quickviewurl,
        data: form,
        success: function (response) {
            if ($("#plpQuickViewModal").length !== 0) {
                $("#plpQuickViewModal").remove();
            }
            $('body').append(response.renderedTemplate);
            $("#plpQuickViewModal").modal('show');

            var element = document.getElementsByClassName("trustpilot-widget top-widget");
            for(var i=0; i<element.length; i++) {
                window.Trustpilot.loadFromElement(element[i]);
                break;
            }
            if (response.gtmData) {
                window.dataLayer.push(response.gtmData);
            }
            customDropDown.loadCustomSelect();
            $('.custom-select2-inner select').select2({
                dropdownParent: $('.custom-select2-inner')
            });
            swatchPdp.swatchDisplay();
            $.spinner().stop();
            setTimeout(function(){
                updateClassPlpQuickViewModal();
            }, 500);
        },
        error: function (error) {
            $.spinner().stop();
        }
    });
});

jQuery('body').on('click', '#plpQuickViewModal .add-to-cart-button', function (e) {
    e.preventDefault();
    $.spinner().start();
    $("#plpQuickViewModal").modal('hide');
    const $item = $(this).parent();
    const addToCartUrl = $item.find('#addtocarturl').val();
    var mattressPids;
    var pidsObj;
    var pid = $item.data('pid');

    if ($('.mattress-product').length) {
        mattressPids = [{
            pid: pid,
            qty: $("#plpQuickViewModal").find('.quantity-select').val()
        }];

        $('.addon-item').each(function () {
            var addOnId = $(this).attr('data-addon-pid');
            if ($('input[id='+addOnId+']').is(':checked')) {
                mattressPids.push({
                    pid: addOnId,
                    qty: 1
                });
            }
        });
        pidsObj = JSON.stringify(mattressPids);
    }
    const form = {
        pid: pid,
        pidsObj: pidsObj,
        quantity: $("#plpQuickViewModal").find('.quantity-select').val(),
        storeId: $item.find('#storeid').val(),
    }
    var showApproachingPromotionMsg = $item.data('showapproachingpromotionmsg');
    $.ajax({
        type: "POST",
        url: addToCartUrl,
        data: form,
        success: function (response) {
            $.spinner().stop();
            handlePostCartAdd(response);
            let htmlToAppend = '';
            if (response.cart.approachingDiscounts.length > 0) {
                for (let i = 0; i < response.cart.approachingDiscounts.length; i++) {
                    var approachingDiscount = response.cart.approachingDiscounts[i];
                    if (approachingDiscount.showApproachingPromotionMsg === true && showApproachingPromotionMsg === true) {
                        htmlToAppend += '<div class="approaching-discounts-inner"><div class="single-approaching-discount text-center">';
                        htmlToAppend += '<span class="single-approaching-discount-message">' + approachingDiscount.discountMsg + '</span>';
                        htmlToAppend += '<div class="group-progress"><p class="progress" data-conditionthreshold="'+ approachingDiscount.conditionThreshold +'" data-merchandisetotal="'+ approachingDiscount.merchandiseTotal +'" data-distancefromconditionthreshold="'+ approachingDiscount.distanceFromConditionThreshold +'"><span class="progress-bar"></span><span class="icon"><span class="fa fa-check"></span></span></p></div>';
                        htmlToAppend += '<span class="single-approaching-discount-disclaimer">' + approachingDiscount.disclaimer + '</span>' || '';
                        htmlToAppend += '</div></div>';
                    }
                }
                $('.approaching-discounts').empty().append(htmlToAppend);
                approachingDiscounts.loadProgress();
            } else {
                $('.approaching-discounts').empty().append(htmlToAppend);
            }
            $("#addToCartMessage-modal").modal('show');
        },
        error: function (error) {
            $.spinner().stop();
        }
    });
});

jQuery('body').on('click', "#plpQuickViewModal .select-store-button", function (e) {
    e.preventDefault();
    $("#plpQuickViewModal").modal('hide');
    window.storeSelectFlyout.openStoreSelectFlyout();

    document.getElementById('storeAddress').focus();
    document.getElementById("storeAddress").select();
});

jQuery('body').on('click', "#plpQuickViewModal .color-attribute", function (e) {
    e.preventDefault();
    if ($(this).hasClass('swatch-link')) {
        return;
    }
    if($(this).hasClass('color-attribute-more')) {
        updateClassPlpQuickViewModal();
    } else {
        var params = {};
        $(this).data('url').replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
            params[key] = value;
        });
        // $.spinner().start();

        const quickviewurl = $("#plpQuickViewModal").find('#quickviewurl').val();
        const form = {
            pid: params.pid,
            uuid: params.uuid,
        }
        // $("#plpQuickViewModal").modal('hide');
        $.ajax({
            type: "get",
            url: quickviewurl,
            data: form,
            success: function (response) {
                // if ($("#plpQuickViewModal").length !== 0) {
                //     $("#plpQuickViewModal").remove();
                // }
                // $('body').append(response.renderedTemplate);
                const parsedHtml = parseHtml(response.renderedTemplate);

                $('#plpQuickViewModal .modal-body').empty();
                $('#plpQuickViewModal .modal-body').html(parsedHtml.body);
                $('#plpQuickViewModal .modal-footer').html(parsedHtml.footer);
                $("#plpQuickViewModal").modal('show');
                addRatingDiv();
                swatchPdp.swatchDisplay();
                $('.color-attribute-more').trigger('click');
                const productQuickView = $('#plpQuickViewModal').find('.product-quickview');
                if (productQuickView.find('#save-price-top').text()){
                    productQuickView.find('.product-overlay-wrapper-top').removeClass('d-none');
                }
                setTimeout(function(){
                    updateClassPlpQuickViewModal();
                }, 200);
                customDropDown.loadCustomSelect();
                $('.custom-select2-inner select').select2({
                    dropdownParent: $('.custom-select2-inner')
                });
                // $.spinner().stop();
            },
            error: function (error) {
                // $("#plpQuickViewModal").modal('show');
                // $.spinner().stop();
            }
        });
    }
});

jQuery('body').on('click', '#plpQuickViewModal .swatches .swatch-link', function (e) {
    e.preventDefault();
    $(this).parent().parent().find('.swatch-circle').removeClass('selected');
    var a = $(this).data('swatch-id');
    $(this).parent().parent().find(".swatch-link[data-swatch-id='" + a + "']").find('.swatch-circle').addClass('selected');
    var pid = $(this).data('product-id');
    var pdpURL = $(this).data('pdpurl');
    var variantURL = "window.location=".concat(pdpURL);
    var $addonItem = $(this).parents('.addon-item');
    $addonItem.find('.product-tile-name-wrap').attr('onclick', variantURL);
    $addonItem.find('.product-tile-name-wrap').find('.card-title.product-tile-name').attr('href', pdpURL);
    $addonItem.find('.card-text.product-tile-subtitle').attr('href', pdpURL);
    $addonItem.find('.product-tile-price a').attr('href', pdpURL);
    $addonItem.find('.card-img a').attr('href', pdpURL);
    $addonItem.find('.card-img a').attr('data-gtm', $(this).attr('data-gtm'));
    $addonItem.find('.product-tile-name-wrap a').attr('data-gtm', $(this).attr('data-gtm'));
    $addonItem.attr('data-addon-gtm', $(this).attr('data-addon-gtm'));
    $(this).closest('.card-body').attr('data-variation-id', pid);
    $(this).closest('.addon-item').attr('data-addon-pid', pid);
    $(this).closest('.addon-item').find('.addon-checkbox').attr('id', pid);
    $(this).closest('.addon-item').find('.custom-control-label').attr('for', pid);
    const imgdata = $(this).data('image');
    if (imgdata && imgdata.url) {
        $(this).closest('.addon-item').find('.plp-image-link img').first().attr('src', imgdata.url);
        $(this).closest('.addon-item').find('.plp-image-link img').first().attr('data-src', imgdata.url);
    }
});

$(window).on('resize', function() {
    updateClassPlpQuickViewModal();
});