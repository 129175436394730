/**
 * TODO: remove isReverse (discussed with Nikolay L.)
 * Function for writing responsive JS
 * Usage:
 *
 *  mediaCheck({
 *      media: '(min-width: 1025px)',
 *      // Switch to Desktop Version
 *      entry: function () {
 *          destroyMobileFunctionality();
 *          initDesktopFunctionality();
 *      },
 *      // Switch to Mobile Version
 *      exit: function () {
 *          destroyDesktopFunctionality();
 *          initMobileFunctionality();
 *      }
 *  });
 *
 **/

export default ({ media, isReverse, ...options }) => {
  const mqChange = (matches, { entry, exit }) => {
    if (matches) {
      if (typeof entry === 'function') {
        entry();
      }
    } else if (typeof exit === 'function') {
      exit();
    }
  };

  options = {
    entry: isReverse ? options.exit : options.entry,
    exit: isReverse ? options.entry : options.exit,
  };

  if (media) {
    const mq = window.matchMedia(media);

    mq.addListener(function () {
      mqChange(mq.matches, options);
    });

    mqChange(mq.matches, options);
  } else {
    mqChange(true, options);
  }
};
