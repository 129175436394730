'use strict';
var focusHelper = require('base/components/focus');
const { isGTMEnabled } = require('../components/helpers');
const toastMessage = require('../components/toast-message');
var approachingDiscounts = require('../components/approaching-discounts');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').attr('data-pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').attr('data-pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    // $('.minicart').trigger('count:update', response);

    if (response && $.isNumeric(response.quantityTotal) && response.quantityTotal > 0) {
        var miniQuantity = $('.minicart .minicart-quantity');
        if (!miniQuantity.hasClass('show')) {
            miniQuantity.addClass('show');
        }
        miniQuantity.text(response.quantityTotal);
        $('.minicart .minicart-link').attr({
            'aria-label': response.minicartCountOfItems,
            title: response.minicartCountOfItems
        });
    }

    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
		if (response.message !== 'Selected item is currently unavailable') {
			if ($('.add-to-cart-messages').length === 0) {
				$('body').append('<div class="add-to-cart-messages"></div>');
			}

			$('.add-to-cart-messages').append(
				`<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${response.message}</div>`,
			);

			setTimeout(function () {
				$('.add-to-basket-alert').remove();
			}, 5000);
		}
	}
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

module.exports = {
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (e, reloadTrue) {
            e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
            $('.modal-content').spinner().start();
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var mattressPids;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail').each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            pid = getPidValue($(this));
            if ($('.mattress-product').length) {
                mattressPids = [{
                    pid: pid,
                    qty: getQuantitySelected($(this))
                }];

                $('.addon-item').each(function () {
                    var addOnId = $(this).attr('data-addon-pid');
                    if ($('input[id='+addOnId+']').is(':checked')) {
                        mattressPids.push({
                            pid: addOnId,
                            qty: 1,
                            options: getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(mattressPids);
            }

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            addToCartUrl = getAddToCartUrl();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: getChildProducts(),
                quantity: getQuantitySelected($(this))
            };

            if (!$('.bundle-item').length) {
                form.options = getOptions($productContainer);
            }

			if (reloadTrue !== '' && addToCartUrl === 'null') {
				addToCartUrl = $('#addtocarturl').val();
			}
            var showApproachingPromotionMsg = $(this).closest('.product-detail').find('.promotion-banner-border').data('showapproachingpromotionmsg');
            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        handlePostCartAdd(data);
                        if (data.cart.approachingDiscounts.length > 0) {
                            let htmlToAppend = '';
                            for (let i = 0; i < data.cart.approachingDiscounts.length; i++) {
                                var approachingDiscount = data.cart.approachingDiscounts[i];
                                if (approachingDiscount.showApproachingPromotionMsg === true && showApproachingPromotionMsg === true) {
                                    htmlToAppend += '<div class="approaching-discounts-inner"><div class="single-approaching-discount text-center">';
                                    htmlToAppend += '<span class="single-approaching-discount-message">' + approachingDiscount.discountMsg + '</span>';
                                    htmlToAppend += '<div class="group-progress"><p class="progress" data-conditionthreshold="'+ approachingDiscount.conditionThreshold +'" data-merchandisetotal="'+ approachingDiscount.merchandiseTotal +'" data-distancefromconditionthreshold="'+ approachingDiscount.distanceFromConditionThreshold +'"><span class="progress-bar"></span><span class="icon"><span class="fa fa-check"></span></span></p></div>';
                                    htmlToAppend += '<span class="single-approaching-discount-disclaimer">' + approachingDiscount.disclaimer + '</span>' || '';
                                    htmlToAppend += '</div></div>';
                                }
                            }
                            $('.approaching-discounts').empty().append(htmlToAppend);
                            approachingDiscounts.loadProgress();
                        }
                        $('body').trigger('product:afterAddToCart', data);
						if (
							!localStorage.getItem('add-to-cart-popup') ||
							localStorage.getItem('add-to-cart-popup') !== 'true'
						) {
							$('#addToCartMessage-modal').modal('show');
						}
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);

						if (
							data &&
							data.error &&
							localStorage.getItem('add-to-cart-popup') &&
							localStorage.getItem('add-to-cart-popup') === 'true'
						) {
							localStorage.removeItem('add-to-cart-popup');
						}

						if (data && data.error && data.message !== '') {
							const errorMsg =
								data.message !== '' ? data.message : 'Selected item is currently unavailable';
							toastMessage.error(errorMsg);
						}

						if (reloadTrue) {
							if (isGTMEnabled && data && !data.error && data.product) {
								const sku = data.product.id;
								const name = data.product.productName + ' - ' + data.product.shortDescription;
								const quantity = data.quantity;
								const currency =
									data.product.price && data.product.sales && data.product.sales.currency
										? data.product.sales.currency
										: data.currency;
								const brand = data.product.brand;
								const variant = data.product.finish;
								const index = '0';
								const priceObj = data.product.price;
								const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;

								const promotions = data.product.promotions;
								let promotionList = [];
								if (promotions) {
									for (let i = 0; i < promotions.length; i++) {
										promotionList.push({
											id: promotions[i].id,
											name: promotions[i].name,
										});
									}
								}
								const category = data.product.categories;
								const categoryData = {
									category1: category && category.length > 0 && category[0] ? category[0].name : '',
									category2: category && category.length > 0 && category[1] ? category[1].name : '',
									category3: category && category.length > 0 && category[2] ? category[2].name : '',
									category4: category && category.length > 0 && category[3] ? category[3].name : '',
									category5: category && category.length > 0 && category[4] ? category[4].name : '',
									list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : '',
									list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : '',
								};

								const price = priceObj.sales ? priceObj.sales.value : priceObj.list.value;
								const instock = data.product.available && data.product.readyToOrder;
								const attributes = instock ? 'In Stock' : 'Out Of Stock';
								$('body').bootstrap('gtm', {
									type: 'add_to_cart',
									data: data.ga4CartHtml,
								});

								$('body').bootstrap('gtm', {
									type: 'addToCart',
									data: data.ga4CartHtml,
								});
							}
							location.reload();
						}
                    },
                    error: function () {
                        $.spinner().stop();
                        location.reload();
                    }
                });
            }
        });
    }
};
