const {isGTMEnabled} = require('../components/helpers');

module.exports = {
	addToCart({
		productSelector = '.add-to-cart-global',
		productDetails = '.quick-view-dialog',
		productPriceSelector = '.sales',
		productTileNameSelector = '.product-name',
		productTileDescriptionSelector = '.product-description',
		productQuantitySelector = '.custom-select',
		productCurrencySelector = 'gtm-currency',
		productAttributes = 'gtm-attributes',
	} = {}) {
		if (!isGTMEnabled()) {
			return;
		}
		const isWishlistPage = $('body').find('.wishlistItemCards').length || null;
		let isModalEnabled = $('body').find('#editWishlistProductModal').length || null;
		document.getElementById('editWishlistProductModal');
		$(document).ready(function() {
			if (isWishlistPage) {
				let isCreated = false;
				if (isModalEnabled) {
					isCreated = false;
				}
				$('body').on('DOMNodeInserted', function() {
					isModalEnabled = $('body').find('#editWishlistProductModal');
					if (isModalEnabled && !isCreated) {
						if ($('.add-to-cart-global').length) {
							isCreated = true;
							$('.add-to-cart-global').on('click', function(e) {
								e.preventDefault();
								const gtmData = $(this).data('gtm');
								const $product = $(this).closest(productSelector);
								const $productDetails = $(this).closest(productDetails);
								const sku = `${$product.data('pid')}`;
								const nameOne = $productDetails
									.find(productTileNameSelector)
									.text()
									.trim();
								const nameTwo = $productDetails
									.find(productTileDescriptionSelector)
									.text()
									.trim();
								const name = `${nameOne} - ${nameTwo}`;
								const price = $productDetails
									.find(productPriceSelector)
									.find('.value')
									.attr('content');
								const quantity = $productDetails
									.find(productQuantitySelector)
									.find('option:selected')
									.text()
									.trim();
								const currency = $product.data(productCurrencySelector) || 'AUD';
								var attributes = $product.data(productAttributes);

								const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
								const variant = $product.data('product-variant') ? $product.data('product-variant') : "";
								const category = $product.data('product-category');
								const index = 0;

								const categoryData = {
									category1: category && category.length > 0 && category[0] ? category[0].name : "",
									category2: category && category.length > 0 && category[1] ? category[1].name : "",
									category3: category && category.length > 0 && category[2] ? category[2].name : "",
									category4: category && category.length > 0 && category[3] ? category[3].name : "",
									category5: category && category.length > 0 && category[4] ? category[4].name : "",
									list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
									list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
								};

								if (!attributes) {
									const instock = $product.data('product-instock');
									attributes = instock ? 'In Stock' : 'Out Of Stock';
								}

								$('body').bootstrap('gtm', {
									type: 'addToCart',
									data: gtmData,
								});
								// Add pinterest tag
								var pScript = document.createElement("script");
								pScript.innerHTML = "pintrk('track', 'AddToCart', {value: " + price + ",order_quantity: " + quantity + ",currency: 'AUD'});";
								document.head.appendChild(pScript);
								$("<noscript><img height='1' width='1' style='display:none;' alt='' src='https://ct.pinterest.com/v3/?tid=2613557919181&event=AddToCart&ed[value]="+ price +"&ed[order_quantity]="+ quantity +"&noscript=1' /></noscript>").appendTo(document.head);
							});
						}
					}
				});
			}
		});
	},
};
