const formValidation = require('sfraScripts/components/formValidation');
const toastMessage = require('../components/toast-message');
var cartData = require('../cart/amart-cart');
var sendClosedEvent = true;
var menuStoreSelectGoogleCount = 0;
var itemCheckFlyout = 3;
var itemDisplayFlyout = itemCheckFlyout;
var storeClass = '';

function preProcessGoogleAddress(address) {
	const prefix = address.substring(0, address.indexOf('<span'));

	if (!prefix) {
		return $(address);
	}
	const $address = $(address.substring(address.indexOf('<span')));
	$address.eq(0).html(prefix + $address.eq(0).html());

	return $address;
}

function parseAutocompleteAddress(place) {
	if (!place || !place.adr_address) return null;
	const $parsed = preProcessGoogleAddress(place.adr_address);
	const result = {
		address1: $parsed.filter('.street-address').text(),
		city: $parsed.filter('.locality').text(),
		stateCode: $parsed.filter('.region').text(),
		postalCode: $parsed.filter('.postal-code').text(),
	};

	return result;
}

function openStoreSelectFlyout() {
	$('body').addClass('modal-open');
	$('body').addClass('disable-overflow');
	$('body > .modal-backdrop').remove();
	$('body').append('<div class="modal-backdrop fade show"></div>');
	$('.storeselect-flyout').animate({
		'right': '0'
	}, 500);
}

function closeStoreSelectFlyout() {
	$('body').removeClass('modal-open');
	$('body').removeClass('disable-overflow');
	$('body > .modal-backdrop').remove();
	$('.storeselect-flyout').animate({
		'right': '-100%'
	}, 500);
}

function changeOptionStoreFlyout() {
	const breakpoint = window.matchMedia('(max-width:991px)');
	$('.btn-see-more-store-flyout').removeClass('d-none');
	if (!breakpoint.matches) {
		itemCheckFlyout = 5;
		storeClass = '.store-list-desktop';
		$('#storeList').addClass('storeList-desktop');
		$('#storeList').removeClass('storeList-mobile');
		if ($('.store-list-desktop .store-item').length >= parseInt($('.storeItems').val())) {
			$('.btn-see-more-store-flyout').addClass('d-none');
		}
	} else {
		itemCheckFlyout = 3;
		storeClass = '.store-list-mobile';
		$('#storeList').removeClass('storeList-desktop');
		$('#storeList').addClass('storeList-mobile');
		if ($('.store-list-mobile .store-item').length >= parseInt($('.storeItems').val())) {
			$('.btn-see-more-store-flyout').addClass('d-none');
		}
	}
	itemDisplayFlyout = itemCheckFlyout;
	$('.btn-see-less-store-flyout').addClass('d-none');
}

module.exports = {
	open: function(){
		$('.storeselect-toggle, .location-storeselect').click(function(e) {
			e.preventDefault();
			$(window).lazyLoadXT();
			window.storeSelectFlyout.openStoreSelectFlyout();
			if ($(this).closest('.cart-delivery-info .js-pickup-option').length > 0) {
				$(this).closest('.cart-delivery-info .js-pickup-option').addClass('active');
			}
			document.getElementById('storeAddress').focus();
			document.getElementById("storeAddress").select();

			window.dataLayer.push({
				event: 'interaction',
				component : 'selectStoreModal',
				action : 'shown'
			});
		});

		$('#storeselect-modal').on("hidden.bs.modal", function () {
			if (sendClosedEvent) {
				window.dataLayer.push({
					event: 'interaction',
					component : 'selectStoreModal',
					action : 'closed'
				});
			} else {
				sendClosedEvent = true;
			}
		});

		$('body').on('click', function (e) {
            if ($(e.target).is('.modal-backdrop') || $(e.target).is('.close-storeselect-flyout')) {
                closeStoreSelectFlyout();
            }
        });
	},
	set: function(){
		$('div#storeList').on('click','.set-storeModal',function(e){
			$('#storeselect-modal').spinner().start();
			let $addToCart = false;

			e.preventDefault();

			let url = $(this).data('url');
			if($('#storeAddress').attr('data-result')) {
				const addrObj = encodeURI($('#storeAddress').attr('data-result'));
				url = url + `&addressObj=${addrObj}`;
			}

			$.ajax({
				url: url,
				type: 'get',
				dataType: 'json',
				success: function(data) {
					$('#store-not-selected').html(`${data.name} ${data.postalCode}`);
					$('.storeselect-name').html(`${data.name} ${data.postalCode}`);
					$('#storeselect-modal').modal('hide');
					sendClosedEvent = false;
					toastMessage.success('Store has been set');
					const name = data.name;
					const region = data.storeRegion;
					$('body').bootstrap('gtm', {
						type: 'storeChanged',
						data: {
							name,
							region,
						},
					});

					window.dataLayer.push({
						event: 'interaction',
						component : 'selectStoreModal',
						action : 'Store Selected',
					});

					if ($addToCart) {
						localStorage.setItem('add-to-cart-popup', true);
						$('button.add-to-cart').trigger('click', ['reloadTrue']);
					} else {
						location.reload();
					}
					$.spinner().start();
				},
			});

			if($("#storeselect-modal").find("#product-id").length > 0){
				var urlToCart = $(".search-results").find('#addtocarturl').val();
				if (!urlToCart) {
					urlToCart = $(".add-to-cart-url").val();
				}
				if (
					(!urlToCart && $('#storeselect-modal').find('button.add-to-cart').length > 0) ||
					$('#storeselect-modal').find('#plpQuickView').length > 0 ||
					$('#storeselect-modal').find('#product-id').val() === ''
				) {
					$addToCart = true;
				} else {
					const form = {
						pid: $('#storeselect-modal')
							.find('#product-id')
							.val(),
						quantity: 1,
						storeId: $(this)
							.parents('.store-details')
							.data('store-id'),
					};

					$.ajax({
						type: 'post',
						url: urlToCart,
						data: form,
						success: function(response) {
							if (response && !response.error) {
								toastMessage.success('Product is Added.');
							} else {
								const errorMsg =
									response.message !== ''
										? response.message
										: 'Selected item is currently unavailable';
								toastMessage.error(errorMsg);
							}
						},
						error: function(error) {
							console.log(error);
						},
					});
				}
			}

			$('#storeselect-modal').spinner().stop();
		});

		$('div.delivery-estimate-results').on('click','.set-storeModal',function(e){
			$('#cartDeliveryAddressModal').spinner().start();
			e.preventDefault();

			let url = $(this).data('url');
			if($('#storeAddress').attr('data-result')) {
				const addrObj = encodeURI($('#storeAddress').attr('data-result'));
				url = url + `&addressObj=${addrObj}`;
			}

			$.ajax({
				url: url,
				type: 'get',
				dataType: 'json',
				success: function(data) {
					$('.storeselect-name').html(`${data.name} ${data.postalCode}`);
					toastMessage.success('Store has been set');
					$(".delivery-estimate-btn").trigger('click');
				},
			});

			$('#cartDeliveryAddressModal').spinner().stop();
		});
	},
	submit: function() {
		$('#storeSelectForm').submit(function(e) {
			e.preventDefault();
			$('#storeselect-modal').spinner().start();
			const el = $(this);
			const lat = $('#storeLat').val();
			const long = $('#storeLong').val();
			const url = `${el.attr('action')}?lat=${lat}&long=${long}`;

			// update all delivery products to pickup
			if($("div.cart-page .card.product-info").length){
				$("input.delivery-option--selector:checked").each(function(){
					if($(this).val() == 'delivery'){
						var name = $(this).prop('name');
						if($("input[name='" + name + "']:not(:checked)").length){
							var target = $("input[name='" + name + "']:not(:checked)");
							$.ajax({
								url: target.closest('.delivery-option').data('url'),
								type: 'POST',
								dataType: 'json',
								data: {
									method: 'pickup',
									uuid: $(this).closest('.delivery-option').data('lineitem'),
								},
								success: function(response) {
								},
								error: function() {
								},
							});
						}
					}
				});
			}

			$.ajax({
				url: url,
				type: 'get',
				success: function(data) {
					if (data.error) {
						$('#storeselect-modal').spinner().stop();
						formValidation(el, {
							fields: {
								storePostcode: data.error,
							},
						});
					} else {
						window.location.reload();
					}
				},
				error: function(e) {
					$('#storeselect-modal').spinner().stop();
					formValidation(el, {
						fields: {
							storePostcode: 'No Stores Found',
						},
					});
				},
			});
		});
		$('#storeSelectFormModal').submit(function(e) {
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
			$('#storeselect-modal').spinner().start();

			const el = $(this);
			const lat = $('#storeLat').val();
			const long = $('#storeLong').val();
			const addr = encodeURI($('#storeAddress').val());
			var url = `${el.attr('action')}?lat=${lat}&long=${long}&address=${addr}`;
			var productDetailContainer = $('.product-detail');
			var pid;
			if( productDetailContainer ) {
				pid = productDetailContainer.attr('data-pid');
				var quantity = productDetailContainer.find('.quantity-select').val();
				if (!quantity) {
					quantity = 1;
				}
				if (pid) {
					url = `${el.attr('action')}?lat=${lat}&long=${long}&pid=${pid}&quantity=${quantity}&address=${addr}`;
				}
			}
			$.ajax({
				url: url,
				type: 'get',
				success: function(data) {
					if (data.error) {
						$('#storeselect-modal').spinner().stop();
						formValidation(el, {
							fields: {
								storePostcode: data.error,
							},
						});
					} else {
						$("#storeList").html(data.storesResultsHtml);
						if(pid){
							$('.storeselect-flyout-product-status').removeClass('d-none');
						}

						window.dataLayer.push({
							event: 'interaction',
							component : 'selectStoreModal',
							action : 'Search Completed',
						});

						changeOptionStoreFlyout();
					}
					$('#storeselect-modal').spinner().stop();
				},
				error: function(e) {
					$('#storeselect-modal').spinner().stop();
					formValidation(el, {
						fields: {
							storePostcode: 'No Stores Found',
						},
					});
				},
			});
		});
	},
	bindAutocomplete: function() {
		if (typeof google === 'undefined' && menuStoreSelectGoogleCount < 1) {
			menuStoreSelectGoogleCount += 1;
			setTimeout(this.bindAutocomplete, 3000);
			return;
		}

		if (typeof google === 'undefined') {
			return;
		}
		var defaultBounds = new google.maps.LatLngBounds(
			new google.maps.LatLng(-33.8902, 151.1759),
			new google.maps.LatLng(-33.8474, 151.2631));
		var options = {bounds: defaultBounds};

		if (typeof $('#storeAddress')[0] !== 'undefined') {
			var autocomplete = new google.maps.places.Autocomplete($('#storeAddress')[0], options);
			const elEstimateInput = $('#storeAddress')[0];

			autocomplete.setComponentRestrictions({'country': ['au']});
			autocomplete.setFields(['adr_address', 'geometry']);

			const setFields = (location = {}) => {
				$('#storeLat').val(location.lat);
				$('#storeLong').val(location.lng);
				$('#storeselect-popover-go').attr('disabled', !location.lat || !location.lng);
			};

			autocomplete.addListener('place_changed', function() {
				const place = autocomplete.getPlace();
				const addrObject = parseAutocompleteAddress(place);
				if (addrObject) {
					if (!addrObject.postalCode) {
						var geometry = place.geometry;
						var latlng = {lat: parseFloat(geometry.location.lat()), lng: parseFloat(geometry.location.lng())};
						cartData.getPostcodeByLat(latlng, function(data){
							addrObject.postalCode = data.postcode;
							elEstimateInput.dataset.result = JSON.stringify(addrObject);
						});
					} else {
						elEstimateInput.dataset.result = JSON.stringify(addrObject);
					}
				} else {
					elEstimateInput.dataset.result = '';
				}

				if (!place.geometry) {
					setFields();
				} else {
					setFields(place.geometry.location);
				}
			});
		}
    },

	showStores: function() {
		if ($('#storeSelectFormModal').length > 0) {
			var storeList = [];
			var $storeListBlock;

			const $storeListWrap = $('#storeList');
			const checkStoreList = function() {
				$storeListBlock = $storeListWrap.find('.store-list');
				$storeListBlockCurrent = $storeListWrap.find(storeClass);
				storeList = $storeListBlock.data('stores');
			};

			if ($storeListWrap[0] !== undefined) {
				const observer = new MutationObserver(checkStoreList);
				observer.observe($storeListWrap[0], {childList: true});
			}

			$(document).on('click', '.btn-see-less-store-flyout', function(e) {
				e.preventDefault();
				if(itemDisplayFlyout === itemCheckFlyout) {
					$('.btn-see-less-store-flyout').addClass('d-none');
				} else {
					var numItem = itemDisplayFlyout / itemCheckFlyout;
					var itemFloat = numItem % 1;
					if (itemFloat === 0) {
						itemDisplayFlyout = (itemDisplayFlyout - itemCheckFlyout < itemCheckFlyout) ? itemCheckFlyout : itemDisplayFlyout - itemCheckFlyout;
					} else {
						itemDisplayFlyout = itemDisplayFlyout - itemCheckFlyout * itemFloat;
					}

					$storeListBlockCurrent.find('.store-item').not(':lt(' + itemDisplayFlyout + ')').hide();
					$('.btn-see-more-store-flyout').removeClass('d-none');

					if(itemDisplayFlyout === itemCheckFlyout) {
						$('.btn-see-less-store-flyout').addClass('d-none');
					}
				}
			});

			$(document).on('click', '.btn-see-more-store-flyout', function(e) {
				e.preventDefault();
				const url = $(this).attr('href');
				const currentlyShownQ = $storeListBlock.find('.store-item:visible').length;
				const storesToShow = storeList.slice(currentlyShownQ, currentlyShownQ + itemCheckFlyout);

				$(this).parents().find('.btn-see-less-store-flyout').removeClass('d-none');

				if (storeList.length <= currentlyShownQ + itemCheckFlyout) {
					$(this).addClass('d-none');
				}

				itemDisplayFlyout = (itemDisplayFlyout + itemCheckFlyout <= storeList.length) ? itemDisplayFlyout + itemCheckFlyout : storeList.length;
				if($storeListBlockCurrent.find('.store-item').length > currentlyShownQ) {
					$storeListBlockCurrent.find('.store-item:lt(' + itemDisplayFlyout + ')').show();
				} else {
					$.ajax({
						url: url,
						type: 'post',
						data: {stores: JSON.stringify(storesToShow)},
						success: function(result) {
							$storeListBlockCurrent.append(result.stores);
						},
					});
				}
			});
		}

		if ($('#cartDeliveryAddressModal').length > 0) {
			let storeListDelivery = [];
			let $storeListDeliveryBlock;

			const $storeDeliveryListWrap = $('.delivery-estimate-results');
			const checkStoreList = function() {
				$storeListDeliveryBlock = $storeDeliveryListWrap.find('.store-list');
				storeListDelivery = $storeListDeliveryBlock.data('stores');
			};

			if ($storeDeliveryListWrap[0] !== undefined) {
				const observer = new MutationObserver(checkStoreList);
				observer.observe($storeDeliveryListWrap[0], {childList: true});
			}

			$(document).on('click', '#showStoresDeliveryBtn', function(e) {
				e.preventDefault();
				const url = $(this).attr('href');
				const currentlyShownQ = $storeListDeliveryBlock.find('.store-item').length;
				const storesToShow = storeListDelivery.slice(currentlyShownQ, currentlyShownQ + 3);

				if (storeListDelivery.length <= currentlyShownQ + 3) {
					$(this).addClass('d-none');
				}

				$.ajax({
					url: url,
					type: 'post',
					data: {stores: JSON.stringify(storesToShow)},
					success: function(result) {
						$storeListDeliveryBlock.append(result.stores);
					},
				})
			});
		}
	},

	showRecommendationPopup: function() {
		$(document).ready(function() {
			if (localStorage.getItem('add-to-cart-popup') && localStorage.getItem('add-to-cart-popup') === 'true') {
				$('#addToCartMessage-modal').modal('show');
				localStorage.removeItem('add-to-cart-popup');
			}
		});
	}
};

$(window).on("orientationchange", function () {
    setTimeout(function() {
        changeOptionStoreFlyout();

		$storeListWrap = $('#storeList');
		$storeListBlockCurrent = $storeListWrap.find(storeClass);
		$storeListBlockCurrent.find('.store-item').not(':lt(' + itemDisplayFlyout + ')').hide();
		$storeListBlockCurrent.find('.store-item:lt(' + itemDisplayFlyout + ')').show();
    }, 150);
});
