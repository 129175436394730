const { isGTMEnabled } = require('../components/helpers');

function pushCheckoutOption(data) {
	var payment_detail = data;
	$('body').bootstrap('gtm', {
		type: 'checkoutOption',
		data,
	});

	if ((data.step == 6 || data.step === "6") && data.option) {
		$.ajax({
			type: 'GET',
			url: $('#gtmeventdata').val(),
			success: function (response) {
				if (!response.error) {
					var data = response.data;
					if (data.ecommerce.items.length > 0) {
						$('body').bootstrap('gtm', {
							type: 'add_payment_info',
							data,
						});
					}
				}
			},
			error: function (error) {
			}
		});
	}
}

function pushCheckout(options) {
	const gtmData = {
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: options,
			},
		},
	};

	window.dataLayer.push(gtmData);
}

function pushCheckoutOptions(options) {
	const gtmData = {
		event: 'checkout_option',
		ecommerce: {
			checkout: {
				actionField: options,
			},
		},
	};

	window.dataLayer.push(gtmData);
}

function updateCheckoutData(options) {
	const products = window.dataLayer.find(function (element) {
		if ('bufferData' in element) {
			return element;
		}
	});
	const gtmData = {
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: options,
				products: products && products.bufferData.products ? products.bufferData.products : [],
			},
		},
	};

	window.dataLayer.push(gtmData);
}

function updateCheckoutDataNoProducts(options) {
	const gtmData = {
		event: 'checkout',
		ecommerce: {
			checkout: {
				actionField: options,
			},
		},
	};

	window.dataLayer.push(gtmData);
}

function stepTracking(
	stepName,
	{
		paymentInputSelectorPaypal = '.paypal-method-name',
		paymentInputSelectorAfterpay = '.AFTERPAY-method-name',
		paymentInputSelectorZip = '.zip-payments-zip-method-name',
		paymentInputSelectorHumm = '.HUMM-method-name',
		paymentInputSelectorPaypalActive = '.nav-link.paypal-tab.active',
		paymentInputSelectorAfterpayActive = '.nav-link.AFTERPAY-tab.active',
		paymentInputSelectorZipActive = '.nav-link.zip-payments-zip-tab.active',
		paymentInputSelectorHummActive = '.nav-link.HUMM-tab.active',
		financeSelected = '#financeSelected',
		registeredCustomer = '.registered-customer-email',
	} = {},
) {
	if (!isGTMEnabled()) {
		return;
	}
	$(document).ready(function () {
		if ($(registeredCustomer).length) {
			/*
			updateCheckoutData({
				step: 5,
				option: 'LoginRegister',
				action: 'checkout',
			});
			*/
			pushCheckoutOption({
				step: 3,
				option: 'LoginRegister',
			});
		}

		if (stepName === 'payment') {
			updateCheckoutDataNoProducts({
				step: 5,
			});
		}

		if (stepName === 'placeOrder') {
			if ($(financeSelected).is(':checked')) {
				pushCheckoutOption({
					step: 4,
					option: 'Finance',
					action: 'checkout_option',
				});
			}

			let paymentMethodName = 'CREDIT_CARD';
			if ($(paymentInputSelectorPaypal).length || $(paymentInputSelectorPaypalActive).length) {
				paymentMethodName = 'payPal';
			}

			if ($(paymentInputSelectorAfterpay).length || $(paymentInputSelectorAfterpayActive).length) {
				paymentMethodName = 'AFTERPAY';
			}

			if ($(paymentInputSelectorZip).length || $(paymentInputSelectorZipActive).length) {
				paymentMethodName = 'Zip';
			}

			if ($(paymentInputSelectorHumm).length || $(paymentInputSelectorHummActive).length) {
				paymentMethodName = 'HUMM';
			}

			const paymentMethod = paymentMethodName;

			pushCheckout({
				step: 6
			});

			pushCheckoutOption({
				step: 6,
				option: paymentMethod,
			});
			/*
						pushCheckoutOption({
							step: 6,
						});
						pushCheckoutOptions({
							step: 6,
							option: paymentMethod,
						});			
			*/
		}
	});
}

module.exports = {
	stepTracking: stepTracking,
};
