const formValidation = require('sfraScripts/components/formValidation');

module.exports = () => {
	$('#subscription-footer-form').submit(e => {
		e.preventDefault();
		$('#subscription-modal').modal('show');
		$('body').addClass('subscription-show');
		$('#subscription-modal .subscription-form :input')[0].value = $('#subscription-footer-form :input')[0].value;
	});

	$('body').on('click', '.notify-me-button', function(e) {
		e.preventDefault();
		if ($('#plpQuickViewModal') && $('#plpQuickViewModal').length > 0) {
			$('#plpQuickViewModal').hide();
			$('body > .modal-backdrop').remove();
		}

		$('#notify-me-modal').modal('show');
		$('body').addClass('subscription-show');
	});

	$('#subscription-modal').on('hide.bs.modal', function() {
		$('body').removeClass('subscription-show');
	});

	$('.subscription-form').submit(function(e) {
		var $form = $(this);
		e.preventDefault();
		var url = $form.data('url');
		const $submitBtn = $form.find('.subscription-submit-btn');
		$submitBtn.attr('disabled', 'true');
		var $recaptcha = $form.find('[data-google-recaptcha]');
		var recaptchaEnabled = $recaptcha.data('recaptchaEnabled');
		var recaptchaSiteKey = $recaptcha.data('recaptchaSiteKey');
		let notifyMe = false;

		function callAjax(token) {
			const $modalParent =
				$form.parents('#subscription-modal') && $form.parents('#subscription-modal').length > 0
					? $form.parents('#subscription-modal')
					: $form.parents('#notify-me-modal');

			if ($modalParent && $modalParent.length > 0) {
				$modalParent.spinner().start();
			} else {
				$.spinner().start();
			}

			var formData = $form.serialize();
			if (token) {
				formData += '&token=' + token;
			}
			if ($form.hasClass('notify-me-form')) {
				notifyMe = true;
			}
			formData += '&notifyMe=' + notifyMe;

			$.ajax({
				url: url,
				type: 'post',
				dataType: 'html',
				data: formData,
				success: function(data) {
					$submitBtn.removeAttr('disabled');
					window.dataLayer.push({
						type: 'interaction',
						component : 'newsletter',
						action : 'successful signup'
					});
					try {
						const res = JSON.parse(data);
						if (res.error) {
							formValidation($form, {
								fields: {
									subscriptionEmail: res.error,
								},
							});
						} else {
							const resultObject = JSON.parse(data);
							$('#subscription-modal .modal-body').html(resultObject.fields.loginEmail);
						}
					} catch (e) {
						$form.parents('.subscription-form-wrap').html(data);
					}
				},
				error: function(e) {
					$submitBtn.removeAttr('disabled');
				},
				complete: function() {
					if ($modalParent.length) {
						$modalParent.spinner().stop();
					} else {
						$.spinner().stop();
					}
				},
			});
		}

		if (recaptchaEnabled) {
			grecaptcha.ready(function () { // eslint-disable-line
				grecaptcha.execute(recaptchaSiteKey, {
					action: 'subscribe'
				}).then(function (token) { // eslint-disable-line
					callAjax(token);
				});
			});
		} else {
			callAjax();
		}
		return false;
	});
};
