require('bootstrap/js/src/modal.js');

const PhotoSwipe = require('photoswipe/dist/photoswipe');
const PhotoSwipeUIDefault = require('photoswipe/dist/photoswipe-ui-default');

function openPhoto(items, index) {
	// Find currently selected photo to open
	const pswpElement = document.querySelectorAll('.pswp')[0];
	const options = {
		index: index || 0, // start at first slide
		bgOpacity: 0.7,
		showAnimationDuration: 200,
		hideAnimationDuration: 200,
		tapToClose: true,
		maxSpreadZoom: 7,
		spacing: 0.12,
	};
	const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault, items, options);
	gallery.init();
}

function initPswpImage() {
    // Initialise photo data
    const items = [];
    $('img.pswp-image').each(function() {
        items.push({
            src: $(this).attr('data-img-full'),
            w: 1610,
            h: 1110,
        });

        // Set listener for photo zoom
        $(this).click(function() {
            openPhoto(items, $(this).data('index'));
        });
    });
}

module.exports.initPswpImage = initPswpImage;