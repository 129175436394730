'use strict';

function loadProgress() {
    var $approaching= $('.approaching-discounts .progress');
    if ($approaching.length > 0) {
        for(var i = 0; i < $approaching.length; i++) {
            var $itemApproaching = $($approaching[i]);
			var conditionthreshold = $itemApproaching.data('conditionthreshold');
            var merchandisetotal = $itemApproaching.data('merchandisetotal');
            var percentWidth = 0;

            if ( conditionthreshold === merchandisetotal ) {
                percentWidth = 100;
                $itemApproaching.find('.icon').addClass('completed');
            } else {
                percentWidth = (merchandisetotal/conditionthreshold) * 100;
                $itemApproaching.find('.icon').removeClass('completed');
            }

            $itemApproaching.find('.progress-bar').css('width', percentWidth + '%');		
		}
    }
}

module.exports = {
	loadProgress: loadProgress,
};
