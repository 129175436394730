/**
 * Extending modules example:
 *
 * const modules = require('utils/bootstrapper').modules;
 * Object.assign(modules, {
 *   pusher: require('./components/pusher').default,
 * });
 *
 **/
export const modules = {
	asyncBootstrapper: require('./async').default,
	mediaCheck: require('./mediaCheck').default,
};

const attrName = 'data-bootstrap';

function initComponent(elem, componentName, config) {
	const component = modules[componentName];

	if (typeof component === 'function') {
		return component(config || {}, elem);
	}

	if ($(elem)[component]) {
		return $(elem)[component](config || {});
	}
}

function asyncBootstrapper(elem) {
	const data = JSON.parse(elem.getAttribute(attrName));

	elem.removeAttribute(attrName);

	Object.entries(data).forEach(([componentName, config]) => {
		initComponent(elem, componentName, config);
	});
}

function jqueryBootstrapper() {
	jQuery.fn.extend({
		bootstrap(componentName, config) {
			this.each((i, elem) => initComponent(elem, componentName, config));
		},
	});
}

export function apply() {
	modules.asyncBootstrapper(`[${attrName}]`, asyncBootstrapper);
	jqueryBootstrapper();
}
