const base = require('./detail');
const {isGTMEnabled} = require('../components/helpers');
require('base/thirdParty/bootstrap');

/**
 * Push the product to the dataLayer after the change attribute
 */
base.watchForDetail = function() {
	if (!isGTMEnabled()) {
		return;
	}

	$('body').on('product:afterAttributeSelect', function(e, {data, container}) {
		const {product} = data;

		container.bootstrap('gtm', {
			type: 'detailView',
			options: {list: 'Attribute Select'},
			data: [
				{
					sku: product.id,
					name: product.productName,
					price:
						product.price.type === 'range'
							? `${product.price.min.sales.formatted} - ${product.price.max.sales.formatted}`
							: (product.price.sales.value && product.price.sales.formatted) ||
							  (product.price.list && product.price.list.value && product.price.list.formatted),
				},
			],
		});
	});
};

module.exports = base;
