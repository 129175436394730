require('bootstrap/js/src/modal.js');

function clearBackdrops() {
	if ($('.modal-backdrop.show').length > 1) {
		$('.modal-backdrop.show')
			.not(':first')
			.remove();
	}
}

function focusSearchField() {
	$('#search-modal input.search-field:text:visible:first').focus();
	$('#search-modal input.search-field')
		.get(0)
		.focus();
	$('#search-modal input.search-field').focus();
	// always reassign focus to text field
	$('#search-modal.show').focus(function() {
		$('#search-modal input.search-field').focus();
	});
}

function moveBackdropBack() {
	// Set modal backdrop for body below header
	$('.modal').on('show.bs.modal', function() {
		clearBackdrops();
		setTimeout(function(){
			$('.modal-backdrop.show').addClass('below-header')
		}, 100);
	});

	$('.modal').on('shown.bs.modal', function() {
		if (!$('.modal-backdrop.show.below-header').length) {
			$('.modal-backdrop.show').addClass('below-header')
		}
	});
}

function toggleSearchHidden() {
	// desktop search panel is closed
	$('.header-container .col-search button.search-button').removeClass('close-search');
}

function toggleSearch() {
	moveBackdropBack();
	$('.header-container .col-search button.search-button').addClass('close-search');
	// Mobile header overlays header
	if ($(window).width() > 1279) {
		$('#search-modal .modal-dialog').css('top', $('header').height());
	} else {
		$('#search-modal .modal-dialog').css('top', 0);
	}
}

function toggleSearchMobile() {
	$('#search-modal .modal-dialog').css('top', 0);
	moveBackdropBack();
}

module.exports = () => {
	$('#toggle-search').on('click', toggleSearch);
	$('#toggle-search-m').on('click', toggleSearchMobile);
	$('#search-modal').on('hidden.bs.modal', toggleSearchHidden);
	$('#search-modal').on('shown.bs.modal', focusSearchField);
	$('.modal').on('show.bs.modal', function() {
		$('body').addClass('modal-open');
		$('body').addClass('disable-overflow');
	});
	$('.modal').on('hidden.bs.modal', function() {
		if(!$('.modal').hasClass('show')) {
			$('body').removeClass('disable-overflow');
			$('body').attr('style', '');
		}
	});
};
