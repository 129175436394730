const toastMessage = require('../components/toast-message');

function setStore({coords}) {
	let urlBase;
	if ($('#store-not-selected').length) {
		urlBase = $('#store-not-selected').data('set');
	} else if ($('#setNearestStore').length) {
		urlBase = $('#setNearestStore').data('set');
	}

	if (urlBase) {
		const url = `${urlBase}?lat=${coords.latitude}&long=${coords.longitude}`;
		$.spinner().start();
		$.ajax({
			url: url,
			type: 'get',
			dataType: 'json',
			success: function(data) {
				if (data.ID) {
					window.location.reload();
				} else {
					$.spinner().stop();
					toastMessage.error('No nearby stores, please select manually');
				}
			},
			error: function() {
				$.spinner().stop();
				toastMessage.error('No nearby stores, please select manually');
			},
		});
	}
}

module.exports = {
	listenToMarkStore: function() {
		$('.set-store').click(e => {
			e.preventDefault();
			$.ajax({
				url: e.currentTarget.href,
				type: 'get',
				dataType: 'json',
				success: function(data) {
					$('#store-not-selected').html(`${data.name} ${data.postalCode}`);
					$('.storeselect-name').html(`${data.name} ${data.postalCode}`);
					if (`${data.phone}`) {
						var $storeContact = $('.store-contact-number');
						var phone = data.phone;
						if ($storeContact.length === 1) {
							if (!$storeContact.hasClass( "nav-link--storecontact" )) {
								$storeContact.addClass("nav-link nav-link--storecontact");
							}
							$storeContact.html(`${data.phone}`);
							$storeContact.attr("onClick", "window.open('tel:" + phone + "');");
						}
					}

					const name = data.name;
					const region = data.storeRegion;
					$('body').bootstrap('gtm', {
						type: 'storeChanged',
						data: {
							name,
							region,
						},
					});
					toastMessage.success('Store has been set');
				},
			});
		});

		$('.store-contact-number').on('click', function() {
			window.dataLayer.push({
				type: 'interaction',
				component : 'phone',
				action : 'phone number clicked'
			});
		});
	},
	setStoreByLocation: function() {
		$('#setNearestStore').on('click', function() {
			if (navigator.geolocation) {
				$('#storeselect-modal').spinner().start();
				navigator.geolocation.getCurrentPosition(setStore, function(error) {
					$.spinner().stop();
					if (error.code === 1) { //  if geolocation is disabled by a user the error code will be 1
						toastMessage.error('Please enable geolocation to find the nearest store');
					} else {
						toastMessage.error('No nearby stores, please select manually');
					}
				});
			}
		});
	},
};
