const { isGTMEnabled } = require('../components/helpers');

function allProductData() {
    var productDetails = '.plp-product';
    var productPriceSelector = '[data-gtm-price]';
    var productTileNameSelector = '.product-tile-name';
    var productTileDescriptionSelector = '.product-tile-subtitle';
    var productQuantitySelector = '.quantity-select';
    var productCurrencySelector = 'product-currency';
    var itemsData = [];

    $(".product.plp-product").each(function () {
        var prod = $(this).find('.qw-buttons');
        const $product = prod;
        const $productDetails = prod.closest(productDetails);
        const sku = `${$product.data('pid')}`;
        const nameOne = $productDetails
            .find(productTileNameSelector)
            .text()
            .trim();
        const nameTwo = $productDetails
            .find(productTileDescriptionSelector)
            .text()
            .trim();
        const name = `${nameOne} - ${nameTwo}`;
        const price = $productDetails
            .find(productPriceSelector)
            .find('.value')
            .attr('content');
        const quantity = 1;
        const currency = $product.data(productCurrencySelector);

        const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
        const variant = $product.data('product-variant') ? $product.data('product-variant') : "";
        const minorCategory = $product.data('product-minor-category') ? $product.data('product-minor-category') : "";
        const category = $product.data('product-category');
        const index = $($(".product.plp-product")).index($(this).closest('.product.plp-product'));
        const priceObj = $product.data('product-priceobj');
        const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
        const promotions = $product.data('promotions');
        var promotionList = [];
        if (promotions) {
            for (let i = 0; i < promotions.length; i++) {
                promotionList.push({
                    id: promotions[i].id,
                    name: promotions[i].name
                })
            }
        }
        const categoryData = {
            category1: category && category.length > 0 && category[0] ? category[0].name : "",
            category2: category && category.length > 0 && category[1] ? category[1].name : "",
            category3: category && category.length > 0 && category[2] ? category[2].name : "",
            category4: category && category.length > 0 && category[3] ? category[3].name : "",
            category5: category && category.length > 0 && category[4] ? category[4].name : "",
            list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
            list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
        }

        var data = {
            item_id: sku,
            item_name: name,
            affiliation: "Amart Online",
            coupon: promotionList,
            currency: currency,
            discount: discount,
            index: index,
            item_brand: brand,
            item_category: categoryData.category1,
            item_category2: categoryData.category2,
            item_category3: categoryData.category3,
            item_category4: categoryData.category4,
            item_category5: categoryData.category5,
            item_list_id: categoryData.list_id,
            item_list_name: categoryData.list_name,
            item_variant: minorCategory,
            item_colour: variant ? "Finish - " + variant : '',
            location_id: "",
            price: price,
            quantity: quantity
        }

        itemsData.push(data);
    });
}

module.exports = {
    addToCart({
        productSelector = '.qw-buttons',
        productDetails = '.plp-product',
        productPriceSelector = '[data-gtm-price]',
        productTileNameSelector = '.product-tile-name',
        productTileDescriptionSelector = '.product-tile-subtitle',
        productQuantitySelector = '.quantity-select',
        productCurrencySelector = 'product-currency',
    } = {}) {
        if (!isGTMEnabled()) {
            return;
        }

        $('body').on('change','.custom-select', function(e){
            setTimeout(()=>{
                allProductData();
            },5000);
        });

        $('.container').on(
            'click',
            '.refinements ul:not(.categories) li button, .refinement-bar button.reset, .show-more button, .filter-value button, .swatch-filter button',
            function (e) {
                setTimeout(() => {
                    allProductData();
                }, 5000);
            });

        $('.qw-buttons').find('.quick-view-button').on('click', function (e) {
            e.preventDefault();
            const $product = $(this).closest(productSelector);
            const gtmData = $product.data('gtm');
            const $productDetails = $(this).closest(productDetails);
            const sku = `${$product.data('pid')}`;
            const nameOne = $productDetails
                .find(productTileNameSelector)
                .text()
                .trim();
            const nameTwo = $productDetails
                .find(productTileDescriptionSelector)
                .text()
                .trim();
            const name = `${nameOne} - ${nameTwo}`;
            const price = $productDetails
                .find(productPriceSelector)
                .find('.value')
                .attr('content');
            const quantity = 1;
            const currency = $product.data(productCurrencySelector);

            const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
            const variant = $product.data('product-variant') ? $product.data('product-variant') : "";
            const category = $product.data('product-category');
            const index = $($(".product.plp-product")).index($(this).closest('.product.plp-product'));
            const priceObj = $product.data('product-priceobj');
            const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
            const promotions = $product.data('promotions');
            var promotionList = [];
            if (promotions) {
                for (let i = 0; i < promotions.length; i++) {
                    promotionList.push({
                        id: promotions[i].id,
                        name: promotions[i].name
                    })
                }
            }
            const categoryData = {
                category1: category && category.length > 0 && category[0] ? category[0].name : "",
                category2: category && category.length > 0 && category[1] ? category[1].name : "",
                category3: category && category.length > 0 && category[2] ? category[2].name : "",
                category4: category && category.length > 0 && category[3] ? category[3].name : "",
                category5: category && category.length > 0 && category[4] ? category[4].name : "",
                list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
                list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
            }

            const instock = $product.data('product-instock');
            const attributes = instock ? 'In Stock' : 'Out Of Stock';

            $('body').bootstrap('gtm', {
                type: 'add_to_cart',
                data: gtmData,
            });

            $('body').bootstrap('gtm', {
                type: 'addToCart',
                data: gtmData,
            });

            // // Add pinterest tag
            // var pScript = document.createElement("script");
            // pScript.innerHTML = "pintrk('track', 'AddToCart', {value: " + price + ",order_quantity: " + quantity + ",currency: 'AUD'});";
            // document.head.appendChild(pScript);
            // $("<noscript><img height='1' width='1' style='display:none;' alt='' src='https://ct.pinterest.com/v3/?tid=2613557919181&event=AddToCart&ed[value]="+ price +"&ed[order_quantity]="+ quantity +"&noscript=1' /></noscript>").appendTo(document.head);
        });

        $('body').on('click', '#plpQuickViewModal .quickview-event-buttons .add-to-cart-button', function(e){
            e.preventDefault();
            const $product = $(this).closest('.quickview-event-buttons');
            const gtmData = $product.data('gtm');
            const $productDetails = $(this).closest("#plpQuickViewModal").find('.pdp-sidebar');
            const sku = `${$product.data('pid')}`;
            const nameOne = $productDetails
                .find(".product-name")
                .text()
                .trim();
            const nameTwo = $productDetails
                .find(".product-description")
                .text()
                .trim();
            const name = `${nameOne} - ${nameTwo}`;
            const price = $productDetails
                .find(productPriceSelector)
                .find('.value')
                .attr('content');
            const quantity = $productDetails
                .find(productQuantitySelector)
                .find('option:selected')
                .text()
                .trim();
            const currency = $product.data(productCurrencySelector);

            const brand = $product.data('product-brand') ? $product.data('product-brand') : "";
            const variant = $product.data('product-variant') ? $product.data('product-variant') : "";
            const category = $product.data('product-category');
            const index = 0;
            const priceObj = $product.data('product-priceobj');
            const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
            const promotions = $product.data('promotions');
            var promotionList = [];
            if (promotions) {
                for (let i = 0; i < promotions.length; i++) {
                    promotionList.push({
                        id: promotions[i].id,
                        name: promotions[i].name
                    })
                }
            }

            const categoryData = {
                category1: category && category.length > 0 && category[0] ? category[0].name : "",
                category2: category && category.length > 0 && category[1] ? category[1].name : "",
                category3: category && category.length > 0 && category[2] ? category[2].name : "",
                category4: category && category.length > 0 && category[3] ? category[3].name : "",
                category5: category && category.length > 0 && category[4] ? category[4].name : "",
                list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
                list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
            }

            const instock = $product.data('product-instock');
            const attributes = instock ? 'In Stock' : 'Out Of Stock';

            $('body').bootstrap('gtm', {
                type: 'add_to_cart',
                data: gtmData,
            });

            $('body').bootstrap('gtm', {
                type: 'addToCart',
                data: gtmData,
            });
		});
	},
};
