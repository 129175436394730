// Image carousel plugin for PDP, Product Set, Bonus products modal on cart
require('bootstrap/js/src/modal.js');
var pswpImage = require('./pswp-product-image');

function initImageCarousel() {
	$('.product-image-primary').slick({
		// lazyLoad: 'ondemand',
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.product-image-nav',
	});

	const $productImageNav = $('.product-image-nav');
	const productImageQ = $productImageNav.find('.image-nav').length;

	$productImageNav.slick({
		// lazyLoad: 'ondemand',
		slidesToShow: 6,
		slidesToScroll: 1,
		asNavFor: '.product-image-primary',
		dots: false,
		centerMode: false,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 3,
				},
			},
		],
	});

	let slidesToShow = $(window).width() > 768 ? 6 : 3;

	if (slidesToShow >= productImageQ) {
		$productImageNav.find('.slick-track').addClass('no-move');
	}

	$(window).on('resize', function() {
		slidesToShow = $(window).width() > 768 ? 6 : 3;

		if (slidesToShow >= productImageQ) {
			$productImageNav.find('.slick-track').addClass('no-move');
		} else {
			$productImageNav.find('.slick-track.no-move').removeClass('no-move');
		}
	});
}

module.exports = function() {
	pswpImage.initPswpImage();
	// Ititialise slick slideshow
	initImageCarousel();
};
