function promotionDetail(product, promotion, index) {
	const productId = product.id;
	const name = product.productName;
	const brand = product.brand;
	const variant = product.finish;
	const minorCategory = product.minorCategory;
	const price = product.price && product.price.list? product.price.list.value : null;
	const currency = product.price && product.price.sales ? product.price.sales.currency : 'AUD';
	const discount = product.price && product.price.list && product.price.sales ? product.price.list.value - product.price.sales.value : 0;
	var promotions = product.promotions;
	var PromotionList = [];
	if (promotions) {
		for (let i = 0; i < promotions.length; i++) {
			var promo = promotions[i];
			if (promo && promo.enabled) {
				PromotionList.push({ id: promo.id, name: promo.name });
			}
		}
	}
	var category = product.categories;
	const categoryData = {
		category1: category && category.length > 0 && category[0] ? category[0].name : "",
		category2: category && category.length > 0 && category[1] ? category[1].name : "",
		category3: category && category.length > 0 && category[2] ? category[2].name : "",
		category4: category && category.length > 0 && category[3] ? category[3].name : "",
		category5: category && category.length > 0 && category[4] ? category[4].name : "",
		list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
		list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
	}
	var data = {
		event: "view_promotion",
		ecommerce: {
			promotion_id: promotion.promotion_id,
			promotion_name: promotion.promotion_name,
			creative_name: promotion.creative_name,
			creative_slot: promotion.creative_slot,
			items: [
				{
					item_id: productId,
					item_name: name,
					affiliation: "Amart Online",
					coupon: PromotionList,
					currency: currency,
					discount: discount.toFixed(2),
					index: index,
					item_brand: brand,
					item_category: categoryData.category1,
					item_category2: categoryData.category2,
					item_category3: categoryData.category3,
					item_category4: categoryData.category4,
					item_category5: categoryData.category5,
					item_list_id: categoryData.list_id,
					item_list_name: categoryData.list_name,
					item_variant: minorCategory,
					item_colour: variant,
					location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
					price: price,
					quantity: 1
				}
			]
		}
	};
	window.dataLayer.push(function () {
		this.set('ecommerce.items', undefined);
	});
	window.dataLayer.push(data);
}

var categoryList = new Set();
$("img").each(function () {
    var url = $(this).closest('a').prop('href');
    if (url) {
        var directories = url.split("/");
        var lastDirecotry = directories[(directories.length - 2)];
        categoryList.add(lastDirecotry);
    }
})
var lst = Array.from(categoryList);
var productDetailContainer = $('.product-detail');
var pid;
var promotionDefault;
if( productDetailContainer ) {
    pid = productDetailContainer.data('pid');
    promotionDefault = productDetailContainer.find('#promotion-id').val();
}
$.ajax({
    type: "get",
    url: $("#getpromotionurl").val(),
    data: { category: lst.join(","), pid : pid, promotionDefault: promotionDefault },
    success: function (response) {
        var lst = response.promotionList;
        var index = 1;
		if (lst && lst.length > 0) {
			const result = lst.filter((thing, index, self) =>
				index === self.findIndex((t) => (
					JSON.stringify(t) === JSON.stringify(thing)
				))
			);
            if (response.product){
                promotionDetail(response.product, result[0], index)
            } else {
                window.dataLayer.push(function () {
                    this.set('ecommerce', undefined);
                });
                window.dataLayer.push({
                    event: 'view_promotion',
                    ecommerce: {
                        items: result
                    }
                });
            }
        }
    },
    error: function (error) {
        console.log(error);
    }
});