import $ from 'jquery';
import domObserver from './domObserver';

/**
 * Sets listener for the appearance of elements which
 * matches specified selector data.
 *
 * @param {(String|Object)} selector - Valid css selector
 * @param {(HTMLElement|Object|String)} [ctx] - Optional context parameter
 *      which might be a DOM element.
 * @param {Function} fn - Callback that will be invoked
 *      when required DOM element appears.
 *
 * @example Listens for appearance of any child node inside of specified component.
 *      $.async('> *', '.cms_page_lsiting', node => {});
 *
 * @example Listens for appearance of 'span' nodes inside of specific context.
 *      $.async('span', document.getElementById('test'), node => {});
 */
$.async = (...args) => {
	const callback = args.pop();

	domObserver.get(args[0], callback, args[1] || document.body);
};

Object.assign($.async, {
	/**
	 * Returns collection of elements found by provided selector data.
	 *
	 * @param {(String|Object)} selector - See 'async' definition.
	 * @param {(HTMLElement|Object|String)} [ctx] - See 'async' definition.
	 * @returns {Array} An array of DOM elements.
	 */
	get: (selector, ctx) => $(selector, ctx).toArray(),

	/**
	 * Sets removal listener of the specified nodes.
	 *
	 * @param {(HTMLElement|Array|ArrayLike)} nodes - Nodes whose removal to track.
	 * @param {Function} fn - Callback that will be invoked when node is removed.
	 */
	remove: (nodes, fn) => {
		domObserver.remove(nodes, fn);
	},

	/**
	 * Remov listener of the specified selector.
	 *
	 * @param {String} selector - Selector that have listened.
	 * @param {Function} fn - Callback that should be removed.
	 */
	off: (selector, fn) => {
		domObserver.off(selector, fn);
	},
});

export default $.async;
