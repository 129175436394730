
'use strict';
var itemCheck = 2;
var itemDisplay = itemCheck;
var lenghtStoreItem = $(".boomgate-nearby-stores .store-item").length;
var flagDesktop = false;
var flagMobile = false;

function actionListStore() {
	const breakpoint = window.matchMedia('(max-width:991px)');
	if (!breakpoint.matches) {
		itemCheck = 4;
        if(!flagDesktop) {
            $(".boomgate-nearby-stores .store-item").hide();
            $('.btn-see-more-store').show();
        }
        flagDesktop = true;
        flagMobile = false;
	} else {
        itemCheck = 2;
        if(!flagMobile) {
            $(".boomgate-nearby-stores .store-item").hide();
            $('.btn-see-more-store').show();
        }
        flagDesktop = false;
        flagMobile = true;
    }
	itemDisplay = itemCheck;
	$('.store-item:lt(' + itemDisplay + ')').show();

    if (lenghtStoreItem <= itemDisplay) {
        $('.list-nearby-store-action').hide();
    } else {
        $('.list-nearby-store-action').show();
    }
    $('.list-nearby-store').removeClass('open');
    $('.list-nearby-store').find('.accordion-collapse').removeClass('show');
    $('.list-nearby-store').find('.collapsible-title').attr('aria-expanded', 'false');
}

$(document).ready(function () {
	actionListStore();

    $('.btn-see-more-store').on('click', function(e) {
        itemDisplay = (itemDisplay + itemCheck <= lenghtStoreItem) ? itemDisplay + itemCheck : lenghtStoreItem;
        $('.store-item:lt(' + itemDisplay + ')').show();
        if (itemDisplay == lenghtStoreItem) {
            $(this).hide();
        }
    });

    $('.btn-see-less-store').on('click', function(e) {
        if(itemDisplay === itemCheck) {
            $('.list-nearby-store .collapsible-title').trigger('click');
        } else {
            var numItem = itemDisplay / itemCheck;
            var itemFloat = numItem % 1;
            if (itemFloat === 0) {
                itemDisplay = (itemDisplay - itemCheck < itemCheck) ? itemCheck : itemDisplay - itemCheck;
            } else {
                itemDisplay = itemDisplay - itemCheck * itemFloat;
            }
            $('.store-item').not(':lt(' + itemDisplay + ')').hide();
            $('.btn-see-more-store').show();
        }
    });
})

$(window).on("orientationchange", function () {
    setTimeout(function() {
        actionListStore();
    }, 150);
});