module.exports = {
  addAriaLabel() {
    setInterval(() => {
      document.querySelectorAll('.dw-apple-pay-button:not([aria-label])').forEach(button => {
        button.setAttribute('aria-label', (button.closest('.product-info') ? 'Buy now' : 'Checkout') + ' with ApplePay');
      });
    }, 1000);
  },

  /**
   * Show the apple pay button only if it is available
   */
  showApplepay() {
    const canMakePayments = window.dw?.applepay && window.ApplePaySession?.canMakePayments() && typeof fetch === 'function';

    if (canMakePayments) {
      document
        .querySelectorAll('.js-applepay-wrapper')
        .forEach(wrapper => wrapper.classList.remove('d-none'))
      ;
    }
  }
};
