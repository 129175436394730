const initialise = () => {
	if ($('.generic-messages').length === 0) {
		$('body').append('<div class="generic-messages"></div>');
	}
};

const remove = id => {
	setTimeout(function() {
		$(`#${id}`).remove();
	}, 5000);
};

const toast = (type, message, id) => {
	$('.generic-messages').append(`<div class="alert alert-${type}" id="${id}" role="alert">${message}</div>`);
};

const types = {
	INFO: 'info',
	SUCCESS: 'success',
	ERROR: 'danger',
};

module.exports = {
	success: message => {
		initialise();
		const id = `success-message-${$('.generic-message').length}`;
		toast(types.SUCCESS, message, id);
		remove(id);
	},
	error: message => {
		initialise();
		const id = `error-message-${$('.generic-message').length}`;
		toast(types.ERROR, message, id);
		remove(id);
	},
	info: message => {
		initialise();
		const id = `info-message-${$('.generic-message').length}`;
		toast(types.INFO, message, id);
		remove(id);
	},
};
